import React, { Fragment, useEffect, useState } from "react";
import config from "../../config";
import { Link, useParams } from "react-router-dom";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import Skeleton from "react-loading-skeleton";
import { Button, Modal } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import MaleFemaleProgressBar from "../../components/common/Charts/MaleFemaleProgressBar";
import GenderGapChart from "../../components/common/Charts/GenderGapChart";
import AntiCoruptionChart from "../../components/common/Charts/AntiCoruptionChart";

const CompanyCharts = () => {
  const { id = "" } = useParams();
  const axios = useAuthenticatedAxios();
  const [GHGChartLoading, setGHGChartLoading] = useState(false);
  const [FemaleRepresentationLoadingChart, setgetFemaleRepresentationDetailsChart] = useState(false);
  const [WGChartLoading, setWGChartLoading] = useState(false);
  const [ECChartLoading, setECChartLoading] = useState(false);
  const [AvgTrainingHoursChartLoading, setAvgTrainingHoursChartLoading] = useState(false);
  const [FatilitiesChartLoading, setFatilitiesChartLoading] = useState(false);
  const [AntiCorruptionChartLoading, setAntiCorruptionChartLoading] = useState(false);
  const [EmployeeTurnoverChartLoading, setEmployeeTurnoverChartLoading] = useState(false);
  const [GHGChartData, setGHGChartData] = useState({ categories: [], series: [], ytitle: '' });
  const [WGChartData, setWGChartData] = useState({ categories: [], series: [], ytitle: '' });
  const [AvgTrainingHoursChartData, setAvgTrainingHoursChartData] = useState({ categories: [], series: [], ytitle: '' });
  const [ECChartData, setECChartData] = useState({ categories: [], series: [], ytitle: '' });
  const [FatilitiesChartData, setFatilitiesChartData] = useState({ categories: [], series: [], ytitle: '' });
  const [waterLoadingChartDetails, setwaterLoadingChartDetails] = useState(false);
  const [trirLoading, setTrirLoading] = useState(false);
  const [waterChartDetails, setWaterConsumptionChart] = useState<any>("");
  const [trirccidentRateChart, setTRIRAccidentRateChart] = useState<any>("");
  const [femaleRepresentationDetailsChart, setFemaleRepresentationDetailsChart] = useState<any>([]);
  const [currentYears, setCurrentYear] = useState<number>(new Date().getFullYear());
  const [AntiCorruptionChartData, setAntiCorruptionChartData] = useState([]);
  const [EmployeeTurnoverChartData, setEmployeeTurnoverChartData] = useState({ categories: [], series: [], ytitle: '' });

  //energy chart state
  const [EnergyChartLoading, setEnergyChartLoading] = useState(false);
  const [energyChartData, setEnergyChartData] = useState<any>(null);

  //MaleFemaleProportion Chart state
  const [maleFemaleProportionChartLoading, setMaleFemaleProportionChartLoading] = useState(false);
  const [maleFemaleProportionChartData, setMaleFemaleProportionChartData] = useState<any>(null);

  //GenderPayGap Chart state
  const [genderPayGapChartLoading, setGenderPayGapChartLoading] = useState(false);
  const [genderPayGapChartData, setGenderPayGapChartData] = useState<any>(null);
  const [selectedTag, setSelectedTag] = useState("All");
  const [companyCharts, setCompanyCharts] = useState(['']);
  const [companyChartsLoading, setCompanyChartsLoading] = useState(false);
  const [companyDetails, setCompanyDetails] = useState<any>("");
  const [companyLoading, setCompanyLoading] = useState(false);

  const fetchCompanyDetails = async () => {
    try {
      setCompanyLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getCompanyDetail}/${id}`
      );
      setCompanyDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setCompanyLoading(false);
    }
  };

  const fetchTRIRAccidentRateChart = async () => {
    try {
      setTrirLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getTRIRAccidentRateChart}?company_id=${id}`
      );

      const data = response?.data?.data || [];
      const chartData = data.length > 0 ? data[0] : null;
      if (chartData) {
        chartData.answare = parseFloat(chartData.answare); // Convert answare to a number
      }
      setTRIRAccidentRateChart(chartData); // Directly set the first object or null
    } catch (error) {
    } finally {
      setTrirLoading(false);
    }
  };


  const fetchGHGEmissionChart = async () => {
    try {
      setGHGChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getGHGEmissionChart}?company_id=${id}`
      );
      setGHGChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setGHGChartLoading(false);
    }
  };

  /* Female Representation on Boards Over Time */
  const fetchFemaleRepresentationOnBorardChart = async () => {

    try {
      setgetFemaleRepresentationDetailsChart(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFemaleRepresentationOnBorardChart}?company_id=${id}`
      );
      setFemaleRepresentationDetailsChart(response?.data?.data);
    } catch (error) {
      console.error("Error fetching female representation data:", error);
    } finally {
      setgetFemaleRepresentationDetailsChart(false);
    }
  };

  const generateSeries = (femaleRepresentationDetailsChart: any[]) => {
    const series: any = [];
    const year1 = currentYears - 1;
    const year2 = currentYears - 2;
    const year3 = currentYears - 3;
  
    femaleRepresentationDetailsChart.forEach((item) => {
      const femaleAnswer = parseFloat(item?.answer || "0");
  
      // Only push the series for the year if there is data
      if (item.year === year1 && femaleAnswer > 0) {
        series.push({
          name: item.year,
          data: [
            { name: "Female", y: femaleAnswer, color: "#351049" },
            { name: "Remaining", y: 100 - femaleAnswer, color: "#5CCBF5" },
          ],
          center: ["20%", "50%"], // Adjust position for the first pie
        });
      } else if (item.year === year2 && femaleAnswer > 0) {
        series.push({
          name: item.year,
          data: [
            { name: "Female", y: femaleAnswer, color: "#351049" },
            { name: "Remaining", y: 100 - femaleAnswer, color: "#5CCBF5" },
          ],
          center: ["50%", "50%"], // Adjust position for the second pie
        });
      } else if (item.year === year3 && femaleAnswer > 0) {
        series.push({
          name: item.year,
          data: [
            { name: "Female", y: femaleAnswer, color: "#351049" },
            { name: "Remaining", y: 100 - femaleAnswer, color: "#5CCBF5" },
          ],
          center: ["80%", "50%"], // Adjust position for the third pie
        });
      }
    });
  
    return series;
  };
  
  // Use the `generateSeries` function to prepare the chart series
  const chartOptions = {
    chart: {
      type: "pie",
      events: {
        render: function () {
          const chart = this as any; // Use 'any' for the chart object
          const yOffset = 70; 
          // Add year label below the first pie chart
          chart.renderer.text('2024', chart.plotLeft + chart.plotWidth * 0.2 - 10, chart.plotTop + chart.plotHeight * 0.9)
            .css({
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#000',
            })
            .add();
  
          // Add year label below the second pie chart
          chart.renderer.text('2023', chart.plotLeft + chart.plotWidth * 0.5 - 10, chart.plotTop + chart.plotHeight * 0.9)
            .css({
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#000',
            })
            .add();
  
          // Add year label below the third pie chart
          chart.renderer.text('2022', chart.plotLeft + chart.plotWidth * 0.8 - 10, chart.plotTop + chart.plotHeight * 0.9)
            .css({
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#000',
            })
            .add();
        },
      },
    },
    title: {
      text: "Female Representation on Boards Over Time",
      align: "center",
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: true,
          format: "{point.y}%", // Display percentage inside the pie slice
          style: {
            fontWeight: "bold",
            fontSize: "14px",
          },
          distance: -120, // Position the label inside the chart
        },
        innerSize: "60%", // Creates a donut chart
        size: "70%", // Adjusts the overall size of the pie
      },
    },
    series: generateSeries(femaleRepresentationDetailsChart), // Dynamically set the series
    credits: {
      enabled: true, // Set to true if you want custom credits
      href: process.env.REACT_APP_CREDITS_URL,
      text: "NeoImpact.com", // The text that will be clickable
    },
  };
  /* end */
  const fetchWGChart = async () => {
    try {
      setWGChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getWGChart}?company_id=${id}`
      );
      setWGChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setWGChartLoading(false);
    }
  };
  const fetchAvgTrainingHoursChart = async () => {
    try {
      setAvgTrainingHoursChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getAvgTrainingHours}?company_id=${id}`
      );
      setAvgTrainingHoursChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setAvgTrainingHoursChartLoading(false);
    }
  };
  const fetchFatilitiesChartData = async () => {
    try {
      setFatilitiesChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFatilitiesChart}?company_id=${id}`
      );
      setFatilitiesChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setFatilitiesChartLoading(false);
    }
  };

  const fetchECChartData = async () => {
    try {
      setECChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getECChart}?company_id=${id}`
      );
      setECChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setECChartLoading(false);
    }
  };

  const fetchWaterConsumptionChart = async () => {
    try {
      setwaterLoadingChartDetails(true);
      const response = await axios.get(
        `${config.apiEndpoints.getWaterConsumptionChart}?company_id=${id}`
      );
      setWaterConsumptionChart(response?.data?.data);
    } catch (error) {
    } finally {
      setwaterLoadingChartDetails(false);
    }
  };
  const fetchAntiCorruptionChartData = async () => {
    try {
      setAntiCorruptionChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getAntiCorruptionChart}?company_id=${id}`
      );

      const data = response?.data?.data || [];
      console.log(response?.data?.data?.series);
      setAntiCorruptionChartData(response?.data?.data?.series?.data || []); // Directly set the first object or null
    } catch (error) {
    } finally {
      setAntiCorruptionChartLoading(false);
    }
  };
  const fetchEmployeeTurnoverChartData = async () => {
    try {
      setEmployeeTurnoverChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getEmployeeTurnover}?company_id=${id}`
      );

      const data = response?.data?.data || [];
      setEmployeeTurnoverChartData(response?.data?.data); // Directly set the first object or null
    } catch (error) {
    } finally {
      setEmployeeTurnoverChartLoading(false);
    }
  };


  const fetchEnergyChartData = async () => {
    try {
      setEnergyChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getEnergyChart}?company_id=${id}`
      );
      setEnergyChartData(response?.data?.data);

    } catch (error) {
    } finally {
      setEnergyChartLoading(false);
    }
  };

  const fetchMaleFemaleProportionChartData = async () => {
    try {
      setMaleFemaleProportionChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getMaleFemaleProportionChart}?company_id=${id}`
      );
      setMaleFemaleProportionChartData(response?.data?.data);

    } catch (error) {
    } finally {
      setMaleFemaleProportionChartLoading(false);
    }
  };

  const fetchGenderPayGapChartData = async () => {
    try {
      setGenderPayGapChartLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getGenderPayGapChart}?company_id=${id}`
      );
      setGenderPayGapChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setGenderPayGapChartLoading(false);
    }
  };

  const fetchCompanyCharts = async () => {
    setCompanyChartsLoading(true)
    try {
      const response = await axios.get(
        `${config.apiEndpoints.getCompanyCharts}?company_id=${id}`
      );
      setCompanyCharts(response?.data?.data?.map((ch:any)=>ch.chart_name));
      
      response?.data?.data?.forEach((item: any) => {
        setChardData(item,item.chart_name);
      })

    } catch (error) {
    } finally {
      setCompanyChartsLoading(false)
    }
  };

  const setChardData = (data: any,chartType:string) => {
    // console.log(data,chartType);
        switch (chartType) {
         case "Emissions":  setGHGChartData(data);  break;
         case "Water": setWaterConsumptionChart(data?.data);break;
         case "Energy": setEnergyChartData(data?.data);break;
         case "Waste": setWGChartData(data);break;
         case "EmployeeTurnover": setEmployeeTurnoverChartData(data);break;
         case "EmploymentClassification": setECChartData(data);break;
         case "MaleFemaleProportion": setMaleFemaleProportionChartData(data?.data);break;
         case "Fatalities": setFatilitiesChartData(data);break;
         case "AverageTrainingHours": setAvgTrainingHoursChartData(data);break;
         case "GenderPayGap": setGenderPayGapChartData(data?.data);break;
         case "TRIR": 
                  const chartData = data?.data.length > 0 ? data?.data[0] : null;
                  if (chartData) {
                    chartData.answare = parseFloat(chartData.answare); // Convert answare to a number
                  }
                  setTRIRAccidentRateChart(chartData);
                    break;
         case "FemaleRepresentationOnBorard": setFemaleRepresentationDetailsChart(data?.data);break;
         case "AntiCorruption": setAntiCorruptionChartData(data?.series?.data);break;
            default: break;            
        }
  }
  useEffect(() => {
    fetchCompanyDetails();
    fetchCompanyCharts();
  }, [id])


  const handleTagClick = (tag: string) => {
    setSelectedTag(tag);
  };

  const components = [
    {
      name: 'Emissions',
      condition: selectedTag === 'Environment',
      component: <GHGChartComponent GHGChartData={GHGChartData} GHGChartLoading={GHGChartLoading} />,
      fun: fetchGHGEmissionChart,
    },
    {
      name: 'Energy',
      condition: selectedTag === 'Environment',
      component: <EnergyChartComponenet energyChartData={energyChartData} EnergyChartLoading={EnergyChartLoading} />,
      fun: fetchEnergyChartData
    },
    {
      name: 'Water',
      condition: selectedTag === 'Environment',
      component: <WaterChartComponent waterChartDetails={waterChartDetails} waterLoadingChartDetails={waterLoadingChartDetails} />,
      fun: fetchWaterConsumptionChart
    },
    {
      name: 'Waste',
      condition: selectedTag === 'Environment',
      component: <WGChartComponent WGChartData={WGChartData} WGChartLoading={WGChartLoading} />,
      fun: fetchWGChart,
    },
    {
      name: 'EmployeeTurnover',
      condition: selectedTag === 'Social',
      component: <EmployeeTurnoverChartComponent EmployeeTurnoverChartLoading={EmployeeTurnoverChartLoading} EmployeeTurnoverChartData={EmployeeTurnoverChartData} />,
      fun: fetchEmployeeTurnoverChartData
    },
    {

      name: 'EmploymentClassification',
      condition: selectedTag === 'Social',
      component: <ECChartComponent ECChartData={ECChartData} AvgTrainingHoursChartLoading={AvgTrainingHoursChartLoading} />,
      fun: fetchECChartData,
    },
    {
      name: 'MaleFemaleProportion',
      condition: selectedTag === 'Social',
      component: <MaleFemaleProportionChartComponent maleFemaleProportionChartData={maleFemaleProportionChartData} maleFemaleProportionChartLoading={maleFemaleProportionChartLoading} />,
      fun: fetchMaleFemaleProportionChartData
    },
    {
      name: 'GenderPayGap',
      condition: selectedTag === 'Social',
      component: <GenderPayGapChartLoadingComponent genderPayGapChartData={genderPayGapChartData} genderPayGapChartLoading={genderPayGapChartLoading} />,
      fun: fetchGenderPayGapChartData,
    },
    {
      name: 'AverageTrainingHours',
      condition: selectedTag === 'Social',
      component: <AvgTrainingHoursChartDataComponent AvgTrainingHoursChartData={AvgTrainingHoursChartData} ECChartLoading={ECChartLoading} />,
      fun: fetchAvgTrainingHoursChart,
    },
    {
      name: 'TRIR',
      condition: selectedTag === 'Social',
      component: <TrirccidentRateChartComponent trirccidentRateChart={trirccidentRateChart} trirLoading={trirLoading} />,
      fun: fetchTRIRAccidentRateChart
    },
    {
      name: 'Fatalities',
      condition: selectedTag === 'Social',
      component: <FatilitiesChartComponent FatilitiesChartData={FatilitiesChartData} FatilitiesChartLoading={FatilitiesChartLoading} />,
      fun: fetchFatilitiesChartData,
    },
    {
      name: 'FemaleRepresentationOnBorard',
      condition: selectedTag === 'Governance',
      component: <FemaleRepresenationChartComponent chartOptions={chartOptions} trirLoading={trirLoading} />,
      fun: fetchFemaleRepresentationOnBorardChart,
    },
    {
      name: 'AntiCorruption',
      condition: selectedTag === 'Governance',
      component: <AntiCorruptionChartComponent AntiCorruptionChartData={AntiCorruptionChartData} AntiCorruptionChartLoading={AntiCorruptionChartLoading} />,
      fun: fetchAntiCorruptionChartData,
    }
  ];

  // useEffect(() => {
  //   { components.map((item: any) => ((item.condition || selectedTag == '') && companyCharts?.includes(item?.name)) && item?.fun()) }
  // }, [companyCharts]);

  return (
    <>
      <div className=" mt-3">
        {/* <div className="col-lg-12">
          <h5 className="fw-semibold mb-0">
            {companyLoading ? (
              <Skeleton height={30} width={600} className="mb-2" />
            ) : companyDetails?.name ? (
              `${companyDetails?.name} ESG Risk Rating Rating Overview`
            ) : (
              ""
            )}
          </h5>
        </div> */}
        <div className="col-lg-12 mt-3">
          <div className="border border-bottom-0 bg-white rounded-top p-3">
            <h5 className="text-black fw-semibold mb-0">
            {companyLoading ? (
              <Skeleton height={30} width={600} className="mb-2" />
            ) : companyDetails?.name ? (
              `${companyDetails?.name} ESG Risk Rating Rating Overview`
            ) : (
              ""
            )}
            </h5>
          </div>
          <div className="border bg-white rounded-bottom p-3">
            <ul className="d-flex flex-wrap justify-content-start grid gap-3">
              {[
                "All",
                "Environment",
                "Social",
                "Governance",
              ]?.map((tag, index) => (
                <li key={index}>
                  <Link
                    style={
                      tag === selectedTag
                        ? { backgroundColor: "#09769F", color: "#fff" }
                        : undefined
                    }
                    to="#"
                    className="tag_1"
                    onClick={() => handleTagClick(tag)}
                  >
                    {tag}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="row mt-4" id="patents">

        {
        companyChartsLoading ? (Array.from({ length: 12 }).map((_, index) => (<Skeleton height={300} width="100%" className="mb-2" />))):
        components.map((item: any, index) => ((item.condition || selectedTag == 'All') && companyCharts?.includes(item?.name)) && <React.Fragment key={index}>{item.component}</React.Fragment>)}
      </div>
    </>
  );
};
export default CompanyCharts;

function GHGChartComponent({ GHGChartData, GHGChartLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">

        {GHGChartLoading ? (
          <Skeleton height={300} width="100%" className="mb-2" />
        ) : (
          <HighchartsReact
            height="2000px"
            highcharts={Highcharts}
            options={{
              chart: {
                type: "area",
              },
              title: {
                text: "GHG Emissions Trend",
              },
              // subtitle: {
              //   text: "Source: Example Data",
              // },
              xAxis: {
                categories: GHGChartData?.categories, //["1990", "2000", "2010", "2019"],
                tickmarkPlacement: "on",
                title: {
                  text: null,
                },
                gridLineWidth: 0, // Adds vertical gridlines between intervals
                gridLineColor: "#BDEAFB",
                labels: {
                  padding: 0, // Remove padding around labels
                  style: {
                    whiteSpace: 'nowrap', // Prevent label wrapping
                    overflow: 'hidden', // Hide overflowing text
                    textOverflow: 'ellipsis' // Add ellipsis (...) for overflow
                  }
                },
                // Reduce point placement for tighter spacing
                pointPlacement: 'between',
                tickInterval: 1, // Ensure ticks are at each category

              },
              yAxis: {
                title: {
                  text: GHGChartData?.ytitle,
                },
                labels: {
                  format: "{value} Gt",
                },
                min: 0,
                tickInterval: 10,
                max: 100,
              },
              tooltip: {
                shared: true,
                valueSuffix: " Gt",
              },
              plotOptions: {
                area: {
                  stacking: "normal",
                  lineColor: "#666666",
                  lineWidth: 1,
                  marker: {
                    enabled: false,
                  },
                  dataLabels: {
                    enabled: true,
                    formatter: function (this: any): string {
                      // Now TypeScript understands `this` is a `Point` object
                      return this.y + "%"; // Add percentage symbol, or format as needed
                    },
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "black", // Customize color
                      marginLeft: "5px",
                    },
                    verticalAlign: "middle", // Position the label vertically
                    align: "left", // Align the label horizontally
                    y: -10,
                    x: 10,
                  },
                },
              },
              series: GHGChartData?.series,
              credits: {
                enabled: true, // Set to true if you want custom credits
                href: process.env.REACT_APP_CREDITS_URL,
                text: "NeoImpact.com", // The text that will be clickable
              },
            }}
          />
        )}
      </div>
    </div></div>
  )
}
function WaterChartComponent({ waterChartDetails, waterLoadingChartDetails }: any) {
  return (
    <div className="col-lg-6"><div className="card card-border table_layout2">
      <div className="position-relative">

        {waterLoadingChartDetails ? (
          <Skeleton height={300} width="100%" className="mb-2" />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "pie",
                height: "400px",
              },
              title: {
                text: "Water",
              },
              legend: {
                enabled: true, // Enable the legend
                layout: "horizontal", // Horizontal layout for the legend
                align: "right", // Align the legend to the right
                verticalAlign: "right", // Vertically center the legend
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true,
                    formatter: function () {
                      return `<b>${this.y}</b>`;
                    },
                    style: {
                      fontSize: "12px",
                      fontWeight: "bold",
                      color: "black",
                    },
                    verticalAlign: "middle", // Position the label vertically
                    align: "left", // Align the label horizontally
                    y: -10,
                    x: 10,
                  },
                },
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.y}</b>",
              },
              series: waterChartDetails,// Use the dynamically generated series data
              credits: {
                enabled: true, // Set to true if you want custom credits
                href: process.env.REACT_APP_CREDITS_URL,
                text: "NeoImpact.com", // The text that will be clickable
              },
            }}
          />
        )}
      </div>
    </div></div>
  )
}

function EnergyChartComponenet({ energyChartData, EnergyChartLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">

        {EnergyChartLoading ? <Skeleton height={300} width="100%" className="mb-2" /> : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "pie",
              },
              title: {
                text: "Energy Consumption Overview",
                align: "center",
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true,
                    useHTML: true,
                    formatter: function (this: any): string {
                      // Render icons with HTML
                      if (this.point.name === "Renewable Energy Consumption") {
                        return `
                                  <div style="text-align: center;">
                                    <img src="https://img.icons8.com/ios-filled/50/4caf50/battery.png" width="40" height="40"  style="margin-bottom: 5px; filter: brightness(0) invert(1);" />
                                    <br>${this.point.name}
                                  </div>`;
                      } else if (this.point.name === "Total Energy Consumption") {
                        return `
                                  <div style="text-align: center;" >
                                    <img src="https://img.icons8.com/ios-filled/50/03a9f4/electrical.png" width="40" height="40"  style="margin-bottom: 5px; filter: brightness(0) invert(1);" />
                                    <br>${this.point.name}
                                  </div>`;
                      } else if (this.point.name === "Center") {
                        return `
                                  <div style="text-align: center;" >
                                   <span style="font-size: 25px; font-weight: bold;">10</span>
                                    <img src="https://img.icons8.com/?size=100&id=37778&format=png&color=000000" width="50" height="50"  style="margin-bottom: 5px; filter: brightness(0) invert(1);" />
                                  </div>`;
                      }
                      return "";
                    },
                    style: {
                      textAlign: "center",
                      fontSize: "12px",
                    },
                    distance: -60, // Bring labels closer to the center
                  },
                  borderWidth: 1,
                  showInLegend: true,
                },
              },
              series: [
                {
                  name: "Energy Consumption",
                  size: "120%",
                  innerSize: "30%",
                  data: energyChartData?.outerData || [],
                },
                {
                  name: "Inner Circle",
                  size: "40%",
                  innerSize: "0%",
                  data: energyChartData?.innerData || [],
                },
              ],
              credits: {
                enabled: true, // Set to true if you want custom credits
                href: process.env.REACT_APP_CREDITS_URL,
                text: "NeoImpact.com", // The text that will be clickable
              },
            }}
          />
        )}
      </div>
    </div></div>
  )
}

function WGChartComponent({ WGChartData, WGChartLoading }: any) {
  return (
    <div className="col-lg-6"><div className="card card-border table_layout2">
      <div className="position-relative">

        {WGChartLoading ? (
          <Skeleton height={300} width="100%" className="mb-2" />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                renderTo: "container",
                type: "column",
              },
              title: {
                text: "Total Annual Waste Generation",
              },
              tooltip: {
                shared: true,
              },
              xAxis: {
                categories: WGChartData.categories,
                crosshair: true,
              },
              yAxis: [
                {
                  title: {
                    text: WGChartData?.ytitle,
                  },
                },
              ],
              series: WGChartData.series,
              credits: {
                enabled: true, // Set to true if you want custom credits
                href: process.env.REACT_APP_CREDITS_URL,
                text: "NeoImpact.com", // The text that will be clickable
              },
            }}
          />
        )}
      </div>
    </div></div>
  )
}

function AntiCorruptionChartComponent({ AntiCorruptionChartData, AntiCorruptionChartLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">
        {
          AntiCorruptionChartLoading ? <Skeleton height={300} width={1500} className="mb-2" /> : (
            <AntiCoruptionChart antiCurroptionData={AntiCorruptionChartData || []} />
          )
        }
      </div>
    </div></div>
  )
}

function TrirccidentRateChartComponent({ trirccidentRateChart, trirLoading }: any) {
  return (
    <div className="col-lg-6"><div className="card card-border table_layout2">
      <div className="position-relative">
        {trirLoading ? (
          <Skeleton height={300} width="100%" className="mb-2" />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                type: "pie",
              },
              title: {
                text: `TRIR${trirccidentRateChart?.year ? `(YEAR:${trirccidentRateChart?.year})` : ''}<br/>${trirccidentRateChart?.answare ? `${trirccidentRateChart?.answare}/6` : ''}`,
                align: "center",
                verticalAlign: "middle",
                y: 60,
                style: {
                  fontSize: "1.1em",
                },
              },
              tooltip: {
                pointFormat: "{series.name}: <b>{point.y}</b>",
              },
              accessibility: {
                point: {
                  valueSuffix: "",
                },
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                      fontWeight: "bold",
                      color: "white",
                    },
                  },
                  startAngle: -90,
                  endAngle: 90,
                  center: ["50%", "75%"],
                  size: "110%",
                },
              },
              series: [
                {
                  name: "TRIR",
                  innerSize: "50%",
                  data: [
                    {
                      name: "Filled",
                      y: trirccidentRateChart?.answare,
                      color: "#007bff", // Blue color for the filled portion
                    },
                    {
                      name: "Remaining",
                      y: 6 - trirccidentRateChart?.answare,
                      color: "#f0f0f0", // White color for the remaining portion
                    },
                  ],
                },
              ],
              credits: {
                enabled: true, // Set to true if you want custom credits
                href: process.env.REACT_APP_CREDITS_URL,
                text: "NeoImpact.com", // The text that will be clickable
              },
            }}
          />
        )}
      </div>
    </div></div>
  )
}

function FemaleRepresenationChartComponent({ chartOptions, trirLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">
        {trirLoading ? (
          <Skeleton height={300} width="100%" className="mb-2" />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions} // Pass dynamic options
          />
        )}
      </div>
    </div></div>
  )
}

function GenderPayGapChartLoadingComponent({ genderPayGapChartData, genderPayGapChartLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">
        {genderPayGapChartLoading ? <Skeleton height={300} width="100%" className="mb-2" /> : (
          <div className="m-5 text-center">
            <h5 className="text-black chart-title">Gender Gap chart</h5>
            <GenderGapChart score={genderPayGapChartData?.gap} />
          </div>
        )}
      </div>
    </div></div>
  )
}

function AvgTrainingHoursChartDataComponent({ AvgTrainingHoursChartData, ECChartLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">

        {
          ECChartLoading ? <Skeleton height={300} width="100%" className="mb-2" /> : (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: "Average Training Hours",
                  align: "center",
                },
                xAxis: {
                  categories: AvgTrainingHoursChartData.categories,
                  title: {
                    text: null,
                  },
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: AvgTrainingHoursChartData.ytitle,
                  },
                  tickInterval: 10,
                  max: 60,
                },
                plotOptions: {
                  column: {
                    stacking: null,
                    dataLabels: {
                      enabled: true, //make it true for showing data in columns
                      style: {
                        fontWeight: "bold",
                        color: "black",
                      },
                      verticalAlign: "bottom",
                      y: 20, // Adjust label position
                    },
                  },
                },
                series: AvgTrainingHoursChartData?.series,
                credits: {
                  enabled: true, // Set to true if you want custom credits
                  href: process.env.REACT_APP_CREDITS_URL,
                  text: "NeoImpact.com", // The text that will be clickable
                },
              }}
            />
          )
        }
      </div>
    </div></div>
  )
}

function FatilitiesChartComponent({ FatilitiesChartData, FatilitiesChartLoading }: any) {
  return (
    <div className="col-lg-6"><div className="card card-border table_layout2">
    <div className="position-relative">

      {
        FatilitiesChartLoading ? <Skeleton height={300} width="100%" className="mb-2" /> : (
          <HighchartsReact
          highcharts={Highcharts}
          options={{
              chart: {
                type: "bar",
              },
              title: {
                text: "Fatalities",
                align: "center",
              },
              xAxis: {
                categories: FatilitiesChartData?.categories || [],
                title: {
                  text: null,
                },
                gridLineWidth: 2, // Adds vertical gridlines between intervals
                gridLineColor: "grey",
              },
              yAxis: {
                min: 0,
                title: {
                  text: "Number of Fatalities",
                  align: "high",
                },
                labels: {
                  overflow: "justify",
                },
              },
              plotOptions: {
                bar: {
                  dataLabels: {
                    enabled: true,
                  },
                },
              },
              legend: {
                reversed: true,
              },
              series: FatilitiesChartData.series || [],
              credits: {
                enabled: true, // Set to true if you want custom credits
                href: process.env.REACT_APP_CREDITS_URL,
                text: "NeoImpact.com", // The text that will be clickable
              },
            }}
          />
        )
      }
    </div>
  </div></div>
  )
}
function MaleFemaleProportionChartComponent({ maleFemaleProportionChartData, maleFemaleProportionChartLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">

        {maleFemaleProportionChartLoading ? <Skeleton height={300} width="100%" className="mb-2" /> : (
          <div className="m-5 text-center">
            <h5 className="text-black chart-title">Male Female Proportion Chart</h5>
            <MaleFemaleProgressBar score={maleFemaleProportionChartData?.male} isFemaleIcon={false} />
            <MaleFemaleProgressBar score={maleFemaleProportionChartData?.female} isFemaleIcon={true} />
          </div>
        )}
      </div>
    </div></div>
  )
}

function ECChartComponent({ ECChartData, AvgTrainingHoursChartLoading }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">

        {
          AvgTrainingHoursChartLoading ? <Skeleton height={300} width="100%" className="mb-2" /> : (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "funnel",
                },
                title: {
                  text: "Employee Classification Funnel",
                },
                plotOptions: {
                  series: {
                    dataLabels: {
                      enabled: true,
                      format: "<b>{point.name}</b> ({point.y:,.0f})",
                      softConnector: true,
                    },
                    // Set the size and proportions of the funnel
                    center: ["50%", "50%"], // Center the funnel
                    width: "50%", // Set the width of the funnel
                    height: "80%", // Set the height of the funnel
                    neckWidth: "20%", // Set the neck width
                    neckHeight: "30%", // Set the neck height
                  },
                },
                legend: {
                  enabled: false,
                },
                series: ECChartData?.series || [],
                credits: {
                  enabled: true, // Set to true if you want custom credits
                  href: process.env.REACT_APP_CREDITS_URL,
                  text: "NeoImpact.com", // The text that will be clickable
                },
                responsive: {
                  rules: [
                    {
                      condition: {
                        maxWidth: 500,
                      },
                      chartOptions: {
                        plotOptions: {
                          series: {
                            dataLabels: {
                              inside: true,
                            },
                            center: ["50%", "50%"],
                            width: "80%",
                            height: "60%",
                          },
                        },
                      },
                    },
                  ],
                },
              }}
            />
          )
        }
      </div>
    </div></div>
  )
}

function EmployeeTurnoverChartComponent({ EmployeeTurnoverChartLoading, EmployeeTurnoverChartData }: any) {
  return (
    <div className="col-12"><div className="card card-border table_layout2">
      <div className="position-relative">

        {
          EmployeeTurnoverChartLoading ? <Skeleton height={300} width={1500} className="mb-2" /> : (
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: "column",
                },
                title: {
                  text: EmployeeTurnoverChartData?.ytitle,
                  align: "center",
                },
                xAxis: {
                  categories: EmployeeTurnoverChartData?.categories,
                  title: {
                    text: null,
                  },
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: EmployeeTurnoverChartData?.ytitle,
                  },
                  stackLabels: {
                    enabled: false, //make it true for showing data on columns
                    style: {
                      fontWeight: "bold",
                      color: "gray",
                    },
                  },
                },
                // tooltip: {
                //   headerFormat: "<b>{point.x}</b><br/>",
                //   pointFormat:
                //     "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
                // },
                plotOptions: {
                  column: {
                    stacking: "normal",
                    dataLabels: {
                      enabled: false, //make it true for showing data in columns
                      style: {
                        fontWeight: "bold",
                        color: "black",
                      },
                    },
                  },
                },
                series: EmployeeTurnoverChartData?.series || [],
                credits: {
                  enabled: true, // Set to true if you want custom credits
                  href: process.env.REACT_APP_CREDITS_URL,
                  text: "NeoImpact.com", // The text that will be clickable
                },
              }}
            />
          )
        }
      </div>
    </div></div>
  )
}


