import { useEffect, useState, useCallback, useRef } from "react";
import { GlobalSearchIcon } from "../../icons";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedAxios } from "../../hooks/useAxios";

export const GlobalSearch = () => {
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const [query, setQuery] = useState(params.get("search"));
  const [isRedirected, setIsRedirected] = useState(false);  // Track redirection state
  const axios = useAuthenticatedAxios();
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const debounceTimer = useRef<any>(null);

  // Assuming that you are setting `authSuccessRedirect` somewhere
  const authSuccessRedirect = "/"; // or whatever your redirect URL is
  
  useEffect(() => {
    setQuery(params.get("search"));
    if (window.location.pathname === authSuccessRedirect) {
      setIsRedirected(true);  // If redirected, hide the search box
    } else {
      setIsRedirected(false);  // Else show it
    }
    //eslint-disable-next-line
  }, [params.get("search"), window.location.pathname]);


  // Make sure useCallback is called unconditionally
  const fetchSuggestions = useCallback(
    async (query: string) => {
      if (!query || query?.length < 3) {
        setSuggestions([]);
        setDropdownVisible(false);
        if (!query.length) {
          setSearchTerm("");
        }
        return;
      }

      setLoading(true);
      try {
        const endpoint = query ? `/company-dropdown?q=${query}` : `/company-dropdown`;
        const response = await axios.get(endpoint);
        setSuggestions(response?.data?.data || []);
        setDropdownVisible(true);
      } catch (error) {
        setSuggestions([]);
      } finally {
        setLoading(false); // Set loading to false once the request is complete
      }
    },
    [axios]
  );

  const handleSuggestionClick = (suggestion: any) => {
    setSearchTerm(suggestion?.name || "");
    setDropdownVisible(false);
    navigate(`/company-detail/${suggestion._id}`);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDropdownVisible(true);
    const value = e.target.value;
    setSearchTerm(value);

    if (!value) {
      setDropdownVisible(false);
      setSuggestions([]);
      fetchSuggestions(""); // Clear suggestions when input is empty
      return;
    }

    // Clear the previous debounce timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new debounce timer
    debounceTimer.current = setTimeout(() => {
      fetchSuggestions(value);
    }, 300); // 300ms debounce delay
  };
  if (isRedirected) {
    return null; // If redirected, don't render the search box
  }

  return (
    <form action="/search" onSubmit={(e) => e.preventDefault()}>
      <div className="search-dropdown">
        <div className="control">
          <div className="selected-value">
            <input
              aria-autocomplete="list"
              aria-labelledby="downshift-12-label"
              autoComplete="off"
              id="downshift-12-input"
              data-transaction-name="search input"
              placeholder="Search companies with at least three letters..."
              className="form-control border text-black font-s-14 sui-search-box__text-input header-s-input ml-5"
              type="text"
              value={searchTerm || ""}  // Use searchTerm state for binding the input value
              name="search"
              onChange={handleInputChange}  // Use the handler to update the state and fetch suggestions
            />
            <div className="icn_search" style={{ left: "15px", top: "15px" }}>
              <GlobalSearchIcon />
            </div>
          </div>
        </div>
      </div>

      {/* Dropdown with suggestions */}
      {isDropdownVisible && (
        <ul className="dropdown-select mt-2 me-2 card">
          {loading ? (
            <li className="text-center">Loading...</li>
          ) : suggestions?.length > 0 ? (
            suggestions.map((suggestion: any, index: number) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                className="dropdown-item"
                style={{ cursor: "pointer" }}
              >
                {suggestion?.name}
              </li>
            ))
          ) : (
            <li className="text-center">No companies found</li>
          )}
        </ul>
      )}
    </form>
  );
};
