import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useFilter, { AppliedFiltersInterface } from "../../hooks/useFilter";
import Filterable from "../../Filterable";
import { newsfilterableFields } from "../../Filterable/FilterableFields";
import config from "../../config";
import DataTable from "../../components/common/DataTable";
import Pagination from "react-js-pagination";
import { formatDate } from "../../components/common/CommonUtils";
import TabHeader from "../../components/common/TabHeader";
import Skeleton from "react-loading-skeleton";
import { Badge } from "../../components/common/CommonUtils";
import { LinkIcon } from "../../icons";
import {
  table_view_column,
  details_view_column,
  details_class,
} from "../../components/common/CommonUtils";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useAuthenticatedAxios } from "../../hooks/useAxios";

interface NewsDetail {
  title: string;
  summary: string;
  url: string;
}


const News = () => {
  const { companyId } = useParams();

  //eslint-disable-next-line
  const [showFilterable, setShowFilterable] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [activeTab, setActiveTab] = useState("table");
  const [readMore, setReadmore] = useState(false);
  const [selectedData, setSelectedData] = useState<NewsDetail | null>(null);
  const axios = useAuthenticatedAxios();

  const [NewsChartLoading,setNewsChartLoading] = useState(false);
  const [NewsChartData, setNewsChartData] = useState({ categories: [], series: [], ytitle: '' });
  const [chartKeywordLimit, setChartKeywordLimit] = useState(10);

  const [selectedFilters, setSelectedFilters] = useState<
    Array<AppliedFiltersInterface>
  >([
    {
      title: "Title",
      field: "title",
      operator: "contains",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Search Title",
      columns: 1,
      columnType: "string",
    },
    {
      title: "Country",
      field: "country.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select Country",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/country-dd",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Continent",
      field: "continent.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: null,
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/continent-dd",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Company",
      field: "companies.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: null,
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/company-dropdown",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Keyword",
      field: "keyword.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: null,
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/keyword-dropdown/News",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Published On",
      field: "publish_date",
      operator: "between_date",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select Date",
      columns: 1,
      columnType: "datetime",
    },
  ]);

  const {
    loading: useFilterLoading,
    records,
    // meta,
    //selectedFilters,
    sortColumn,
    removeSortColumn,
    resetSorting,
    applyFilters,
    exportRecords,
    // fetchRecords,
    addFilter,
    clearFilter,
    removeFilter,
    resetFilter,
    onFilterColumnSelectHandler,
    onFilterOperatorSelectHandler,
    onFilterValueOneChangeHandler,
    onFilterValueTwoChangeHandler,
    error,
    updateCursor,
    buildQuery
  } = useFilter({
    endpoint: companyId
      ? config.apiEndpoints.getCompanyNews + "?company_id=" + companyId
      : config.apiEndpoints.getAllNews,
    exportIdentifier: "News",
    exportEndpoint: "/export/news",
    queryParams: [{ page: activePage }],
    filterArray: selectedFilters,
    selectedFilters: selectedFilters,
    setSelectedFilters: setSelectedFilters,
  });
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const handleReadMoreToggle = (rowIndex: number) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };
  useEffect(() => {
    if (companyId) {
      fetchCompany(companyId);
    }
    //eslint-disable-next-line
  }, [companyId]);

  const fetchCompany = async (companyId: string) => {
    const response = await axios.get(
      config.apiEndpoints.getCompanyDetail + "/" + companyId
    );
    const response_data = response?.data;
    setSelectedFilters((prev: any) =>
      prev.map((filter: any) => {
        if (filter?.field === "company.name") {
          return {
            ...filter,
            ["query_1"]: [
              { name: response_data?.data?.name, id: response_data?.data?._id },
            ],
          };
        }
        return filter;
      })
    );
  };
  const tableColumnsForSorting = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      width: "40%",
      render: (row: any) => (
        <>
          {/* Title */}
          <span className="fw-semibold">
            <Link to={row.url} target="_blank" rel="noopener noreferrer">
              {row?.title?.length > 50
                ? row?.title?.substring(0, 50) + "..."
                : row?.title}
            </Link>
          </span>
          <br />
          {/* Abstract with Read More/Read Less */}
          <div className="mt-2">
            <span>
              {expandedRow === records?.data.indexOf(row)
                ? row.text || "--"
                : (row.text || "--").split(" ").slice(0, 10).join(" ")}
              {row.text?.split(" ").length > 10 && (
                <>
                  <button
                    className="btn btn-link p-0 ms-1"
                    onClick={() =>
                      handleReadMoreToggle(records?.data.indexOf(row))
                    }
                  >
                    {expandedRow === records?.data.indexOf(row)
                      ? "Read Less"
                      : "Read More"}
                  </button>
                </>
              )}
            </span>
          </div>
          {/* Keyword Badge */}
          {Array.isArray(row?.keyword)
            ? row?.keyword?.map((keyword: any) => (
                <Badge text={`${keyword?.name || "--"}`}   className="mt-2"/>
              ))
            :  <Badge text={`${row?.keyword || "--"}`}  className="mt-2" />} 
        </>
      ),
    },
    {
      name: "Author",
      key: "author",
      label: "Author",
      render: (row: any) => row?.author || "--",
      sortable: true,
      width: "20%",
    },
    {
      name: "Dimension	",
      key: "dimension",
      label: "Dimension	",
      render: (row: any) => row?.dimension || "--",
      sortable: true,
      width: "20%",
    },
    {
      key: "publish_date",
      sortable: true,
      label: "Publish Date",
      render: (row: any) => formatDate(row?.publish_date) || "--",
      width: "20%",
    },
  ];

  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };
  const handleRowClick = (detail: NewsDetail) => {
    setSelectedData(detail);
  };

  useEffect(() => {
    if (records?.data?.length > 0) {
      setSelectedData({
        title: records.data[0]?.title,
        summary: records.data[0]?.summary,
        url: records.data[0]?.url,
      });
    } else {
      setSelectedData(null);
    }
  }, [records]);

  const fetchNewsChartDetail = async () => {
    try {
      setNewsChartLoading(true);
      let params :any = buildQuery();
       delete params?.page;
       delete params?.paginate;
      const response = await axios.get(
        `${config.apiEndpoints.getNewsChart}`,
        {
          params: params,
        }
      );
      setNewsChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setNewsChartLoading(false);
    }
  };
  useEffect(()=>{
    if(activeTab == 'charts'){
      fetchNewsChartDetail();
    }
  },[activeTab,selectedFilters])
  return (
    <>
      <h3 className="fw-semibold font-s-20 text_purple mb-3">News</h3>
      <section>
            <div className="companies_filter rounded px-4 pt-4 pb-3 bg-white shadow-sm">
              <Filterable
                loading={useFilterLoading}
                canExport={true}
                showFilterable={showFilterable}
                selectedFilters={selectedFilters}
                sortColumn={sortColumn}
                removeSortColumn={removeSortColumn}
                resetSorting={resetSorting}
                filterableFields={newsfilterableFields}
                sortableFields={[]}
                queryParams={[]}
                exportRecords={exportRecords}
                applyFilters={applyFilters}
                addFilter={addFilter}
                clearFilter={clearFilter}
                removeFilter={removeFilter}
                resetFilter={resetFilter}
                onFilterColumnSelectHandler={onFilterColumnSelectHandler}
                onFilterOperatorSelectHandler={onFilterOperatorSelectHandler}
                onFilterValueOneChangeHandler={onFilterValueOneChangeHandler}
                onFilterValueTwoChangeHandler={onFilterValueTwoChangeHandler}
                error={error}
              />
            </div>
      </section>
      <TabHeader 
      activeTab={activeTab} 
      tabs={[
        { label: "Listing", value: "table" },
        { label: "Dashboard", value: "charts" },
      ].filter(Boolean)}
      onTabChange={setActiveTab} />
      {activeTab === "table" && (
        <div className="row">
          <div className={`col-lg-${table_view_column}`}>
            <section className="mt-3 mt-lg-4">
              <div className="row">
                <div className="col-12">
                  <div className="card card-border table_layout2">
                    <div className="position-relative">
                      <DataTable
                        loading={useFilterLoading}
                        data={records?.data}
                        columns={tableColumnsForSorting}
                        isBorderdTable={false}
                        emptyMessage={{
                          title: "No News Found",
                        }}
                        sortColumn={sortColumn}
                        onRowClick={(row: any) =>
                          handleRowClick({
                            title: row?.title,
                            summary: row?.summary,
                            url: row?.url,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {config.paginator === "cursor" ? (
                <div className="pagination">
                  {records?.meta?.has_previous ? (
                    <Link
                      to={""}
                      onClick={() =>
                        updateCursor(records?.meta?.previous_token)
                      }
                      className="btn btn-primary"
                      style={{ marginRight: "5px" }}
                    >
                      Previous
                    </Link>
                  ) : (
                    <button
                      className="btn btn-secondary"
                      style={{ marginRight: "5px" }}
                      disabled
                    >
                      Previous
                    </button>
                  )}
                  {records?.meta?.has_next ? (
                    <Link
                      to={""}
                      onClick={() => updateCursor(records.meta?.next_token)}
                      className="btn btn-primary ml-1"
                    >
                      Next
                    </Link>
                  ) : (
                    <button className="btn btn-secondary" disabled>
                      Next
                    </button>
                  )}
                </div>
              ) : (
                <Pagination
                  activePage={activePage}
                  itemsCountPerPage={10}
                  totalItemsCount={records?.total || 0}
                  pageRangeDisplayed={10}
                  itemClass="page-item"
                  linkClass="page-link"
                  onChange={handlePageChange}
                />
              )}
            </section>
          </div>

          <div className={`col-lg-${details_view_column} ${details_class}`}>
            <div className="card card-border mt-4">
              <div className="card-header">Detail</div>
              <div className="card-body">
                {useFilterLoading ? (
                  <Skeleton height={200} width={"100%"} />
                ) : selectedData?.summary ? (
                  <>
                    <h3>
                      <Link
                        title="Link"
                        className="mt-5"
                        to={selectedData?.url || ""}
                        target="_blank"
                      >
                        <span className="fw-semibold">
                          {selectedData.title}
                        </span>
                      </Link>
                    </h3>
                    <p>
                      {readMore
                        ? selectedData?.summary
                        : selectedData?.summary.substring(0, 200)}
                      ...
                      {selectedData?.summary?.length > 200 && (
                        <Link
                          to={""}
                          className="mt-5"
                          onClick={(): void => setReadmore(!readMore)}
                        >
                          {readMore ? "Read Less" : "Read More"}
                        </Link>
                      )}
                    </p>
                  </>
                ) : (
                  <p>Select a news to see details here.</p>
                )}
                <div className="text-end mt-3">
                  <span className="border border-1 border-primary rounded py-1 px-2">
                    <Link
                      title="Link"
                      className="mt-5"
                      to={selectedData?.url || ""}
                      target="_blank"
                    >
                      <LinkIcon />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === "charts" && (
        <div className="row">
          <div className="col-lg-12 mt-3">
            <div className="card card-border mb-4">
              <div className="card-body">
                {NewsChartLoading ? (
                  <Skeleton height={300} width="100%" className="mb-2" />
                ) : (
                  <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                      chart: {
                        type: "line",
                      },
                      title: {
                        text: "",
                      },
                      xAxis: {
                        categories: NewsChartData?.categories.slice(0,chartKeywordLimit)
                      },

                      yAxis: {
                        title: {
                          text: NewsChartData?.ytitle
                        }
                      },
                      legend: {
                        layout: 'vertical',
                        align: 'right',
                        verticalAlign: 'top',
                      },
                      exporting: {
                        enabled: false
                      },
                      credits: {
                        enabled: false
                      },
                      series: NewsChartData?.series.map((sr:any)=>{
                        return {...sr,['data']:sr?.data.slice(0,chartKeywordLimit)}
                      }),

                    }}
                  />)}
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === "states" && (
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-border mb-4">
              <div className="card-body">
                <h5>States View</h5>
                {/* Add detailed "States" content here */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default News;
