import React from "react";
import { ReactComponent as MaleIcon } from "../../../assets/img/MaleGenderGap.svg";
import { ReactComponent as FemaleIcon } from "../../../assets/img/FemaleGenderGap.svg";

type IconFillerProps = {
  score: number; // Score between 0 and 100
};

const GenderGapChart: React.FC<IconFillerProps> = ({ score=0 }) => {
  const totalIcons = 10; // Total number of icons (5 female + 5 male)
  // Calculate female icons based on the score
  const femaleIcons = Math.ceil((score / 100) * totalIcons);
  // Calculate male icons
  const maleIcons = totalIcons - femaleIcons;

  const scorePerIcon = 100 / totalIcons; // Score each icon represents
  const filledIcons = Math.floor(score / scorePerIcon); // Fully filled icons
  const partialFill = (score % scorePerIcon) / scorePerIcon; // Partial fill for the last icon
  const renderIcon = (
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
    isFilled: boolean,
    isPartial: boolean,
    fillColor: string,
    index: number
  ) => {
    return (
      <div
        key={index}
        style={{
          position: "relative",
          width: "50px",
          height: "50px",
          opacity: isFilled || isPartial ? 1 : 0.5, // Dim the non-filled icons
        }}
      >
        <Icon
          style={{
            width: "100%",
            height: "100%",
          }}
        />
        {isPartial && (
          <svg width="0" height="0">
            <defs>
              <linearGradient
                id={`partial-gradient-${index}`}
                x1="0%"
                y1="0%"
                x2="100%"
                y2="0%"
              >
                <stop offset={`${partialFill * 100}%`} stopColor={fillColor} />
                <stop
                  offset={`${partialFill * 100}%`}
                  stopColor="#f0f0f0"
                />
              </linearGradient>
            </defs>
          </svg>
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "1px",
        marginTop: "10px",
        justifyContent: "center",
      }}
    >
      {Array.from({ length: femaleIcons }).map((_, index) => {
        const isFilled = index < filledIcons;
        const isPartial = index === filledIcons && score % scorePerIcon > 0;
        return renderIcon(FemaleIcon, isFilled, isPartial, "#ff69b4", index);
      })}
      {Array.from({ length: maleIcons }).map((_, index) => {
        const adjustedIndex = index + femaleIcons;
        const isFilled = adjustedIndex < filledIcons;
        const isPartial =
          adjustedIndex === filledIcons && score % scorePerIcon > 0;
        return renderIcon(MaleIcon, isFilled, isPartial, "#007bff", adjustedIndex);
      })}
      <span
        style={{
          marginLeft: "10px",
          marginTop: "10px",
          textAlign: "center",
          fontSize: "20px",
        }}
      >
        {`${score}%`}
      </span>
    </div>
  );
};

export default GenderGapChart;
