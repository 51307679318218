import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as RouterRoutes,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-loading-skeleton/dist/skeleton.css";
import { AuthProvider } from "../contexts/AuthContext";
import { DefaultLayout } from "../layouts/DefaultLayout";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/SignUp";
import { ProtectedLayout } from "../layouts/ProtectedLayout";
import Dashboard from "../pages/Dashboard";
import Portfolio from "../pages/portfolio/Portfolio";
import RecentVisited from "../pages/portfolio/RecentVisited";
import Companies from "../pages/companies/Companies";
import Profile from "../pages/account/Profile";
import NotFound from "../pages/NotFound";
import Patent from "../pages/dataset/Patent";
import PatentDetail from "../pages/dataset/PatentDetail";
import Dataset from "../pages/dataset/Dataset";
import Jobs from "../pages/dataset/Jobs";
import JobDetail from "../pages/dataset/JobDetail";
import Regulations from "../pages/dataset/regulations/Regulations";
import News from "../pages/dataset/News";
import SectorView from "../pages/sector/SectorView";
import RegulationDashboard from "../pages/dataset/regulations/RegulationDashboard";
import RegulationList from "../pages/dataset/regulations/RegulationList";
import CompanyLocation from "../pages/companies/CompanyLocation";
import CompanyDetail from "../pages/companies/CompanyDetail";
import ForgotPassword from "../pages/auth/ForgotPassword";
import UpdatePassword from "../pages/auth/UpdatePassword";
import ContactUs from "../pages/contactus/ContactUs";
import CompanyCharts from "../pages/companies/CompanyCharts";

const Routes = () => {
  return (
    <>
      <Router
        future={{
          v7_relativeSplatPath: true,
          v7_startTransition: true,
        }}
      >
        <AuthProvider>
          <ToastContainer
            theme="colored"
            position="top-right"
            autoClose={1000}
          />
          <RouterRoutes>
            <Route element={<DefaultLayout />}>
              <Route path="/login" element={<Login />}></Route>
            </Route>
            <Route element={<DefaultLayout />}>
              <Route path="/sign-up" element={<SignUp />}></Route>
            </Route>
            <Route element={<DefaultLayout />}>
              <Route
                path="/forgot-password"
                element={<ForgotPassword />}
              ></Route>
            </Route>
            <Route element={<DefaultLayout />}>
              <Route
                path="/update-password/:token/:email"
                element={<UpdatePassword />}
              ></Route>
            </Route>

            <Route element={<ProtectedLayout />}>
              <Route path="/" element={<Dashboard />}></Route>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/portfolio" element={<Portfolio />}></Route>
              <Route path="/recent-visited" element={<RecentVisited />}></Route>
              <Route path="/companies" element={<Companies />}></Route>
              <Route
                path="/company-detail/:id"
                element={<CompanyDetail />}
              ></Route>
               <Route
                path="/company-charts/:id"
                element={<CompanyCharts />}
              ></Route>
              <Route
                path="/companies/locations/:id"
                element={<CompanyLocation />}
              ></Route>
              <Route path="/profile">
                <Route path="" element={<Profile />}></Route>
              </Route>
              <Route path="/dataset" element={<Dataset />}></Route>
              <Route
                path="/dataset/patent/:companyId?"
                element={<Patent />}
              ></Route>
              <Route
                path="/patent-detail/:id"
                element={<PatentDetail />}
              ></Route>
              <Route
                path="/dataset/jobs/:companyId?"
                element={<Jobs />}
              ></Route>
              <Route path="/job-detail/:id" element={<JobDetail />}></Route>
              <Route
                path="/dataset/regulations"
                element={<Regulations />}
              ></Route>
              <Route
                path="/dataset/news/:companyId?"
                element={<News />}
              ></Route>
              <Route
                path="/dataset/deals/:companyId?"
                element={<Regulations />}
              ></Route>
              <Route
                path="/dataset/social-media"
                element={<Regulations />}
              ></Route>
              <Route
                path="/regulation-dashboard"
                element={<RegulationDashboard />}
              ></Route>
              <Route
                path="/regulations-list/:companyId?"
                element={<RegulationList />}
              ></Route>
              <Route path="/sector/:id" element={<SectorView />}></Route>
            </Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
            <Route path="*" element={<NotFound />} />
          </RouterRoutes>
        </AuthProvider>
      </Router>
    </>
  );
};
export default Routes;
