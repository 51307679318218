import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAxios } from "../../hooks/useAxios";
import config from "../../config";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ContactUsForm: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState<string | null>(null);
  const axios = useAxios();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      mobile_number: "",
      description: "",
      is_agree: false,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("First name is required"),
      last_name: Yup.string().required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      mobile_number: Yup.string().required("Mobile number is required"),
      description: Yup.string().required("Description is required"),
      is_agree: Yup.boolean()
        .oneOf([true], "You must agree to the privacy policy")
        .required("Agreement is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!recaptchaValue) {
        toast.error("Please complete the reCAPTCHA.");
        return;
      }

      setIsSubmitting(true);
      setErrorMessage(""); // Clear previous errors

      try {
        const data = {
          ...values,
          captcha: recaptchaValue,
        };

        const response = await axios.post(config.apiEndpoints.contactUs, data);
        if (response?.data?.status === 201) {
          toast.success(response?.data?.message);
          resetForm();
          setRecaptchaValue(null);
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } catch (error: any) {
        toast.error(
          error?.response?.data?.message || "Error during form submission."
        );
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const handleRecaptchaChange = (value: string | null) => {
    setRecaptchaValue(value);
  };

  return (
    <div className="col-lg-6">
      <div className="me-lg-4">
        <div className="bg-white rounded-5 p-5 rq_cutom_demo">
          <h5 className="text-black fw-semibold mb-3">Contact Us</h5>
          <form onSubmit={formik.handleSubmit}>
            {/* First Name */}
            <div className="row">
              <div className="col-md-6 mb-5">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name *"
                    name="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  <label>First Name*</label>
                </div>
              </div>

              {/* Last Name */}
              <div className="col-md-6 mb-5">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name *"
                    name="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  <label>Last Name*</label>
                </div>
              </div>
            </div>
            <div className="row">
              {/* Email */}
              <div className="col-md-6 mb-5">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Business Email Address *"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  <label>Business Email*</label>
                </div>
              </div>

              {/* Phone Number */}
              <div className="col-md-6 mb-5">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone Number"
                    name="mobile_number"
                    value={formik.values.mobile_number}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                  />
                  <label>Phone Number*</label>
                </div>
              </div>
            </div>
            {/* Research Requirements */}
            <div className="mb-4">
              <div className="form-floating">
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder="State your research requirements"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  required
                ></textarea>
                <label>State your research requirements</label>
              </div>
            </div>

            {/* Privacy Policy Agreement */}
            <div className="mb-4 form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                name="is_agree"
                checked={formik.values.is_agree}
                onChange={formik.handleChange}
                required
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Yes, I have read the{" "}
                <Link
                  title="Privacy policy"
                  to="https://neoimpact.com/privacy-policy/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy </Link> and 
                  <Link
                  title="Terms and Conditions"
                  to="https://neoimpact.com/terms-conditions/"
                  target="_blank"
                  rel="noopener noreferrer"
                   > Terms and Conditions </Link>
                   
                   The website is secure and your personal details are safe.
              </label>
            </div>

            {/* Google reCAPTCHA */}
            <div className="mb-4">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY || ""}
                onChange={handleRecaptchaChange}
              />
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-start">
              <button
                type="submit"
                className="btn btn-primary-horizon btn-lg mt-4"
                title="Submit"
                disabled={isSubmitting}
              >
                Schedule Free Demo
              </button>
            </div>
            {errorMessage && <div className="text-danger">{errorMessage}</div>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactUsForm;
