import React, { useEffect, useState } from "react";
import { formatDate } from "../../components/common/CommonUtils";
import config from "../../config";
import DataTable from "../../components/common/DataTable";
import Pagination from "react-js-pagination";
import useFilter, { AppliedFiltersInterface } from "../../hooks/useFilter";
import Filterable from "../../Filterable";
import { jobfilterableFields } from "../../Filterable/FilterableFields";
import { Link, useParams } from "react-router-dom";
import TabHeader from "../../components/common/TabHeader";
import Skeleton from "react-loading-skeleton";
import { LinkIcon } from "../../icons";
import {table_view_column,details_view_column,details_class} from "../../components/common/CommonUtils";


import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useAuthenticatedAxios } from "../../hooks/useAxios";

interface JobDetail {
  summary: string;
  url: string;
  job_title: string;
}
const Jobs = () => {
  const { companyId } = useParams();
  const axios = useAuthenticatedAxios();

  const [activePage, setActivePage] = useState(1);
  //eslint-disable-next-line
  const [showFilterable, setShowFilterable] = useState(true);
  const [readMore, setReadmore] = useState(false);
  const [activeTab, setActiveTab] = useState("table");
  const [selectedData, setSelectedData] = useState<JobDetail | null>(null);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const handleReadMoreToggle = (rowIndex: number) => {
    setExpandedRow(expandedRow === rowIndex ? null : rowIndex);
  };

    const [JobChartLoading,setJobChartLoading] = useState(false);
    const [JobChartData, setJobChartData] = useState({ categories: [], series: [], ytitle: '' });
    const [chartKeywordLimit, setChartKeywordLimit] = useState(10);
    
  const [selectedFilters, setSelectedFilters] = useState<
    Array<AppliedFiltersInterface>
  >([
    {
      title: "Title",
      field: "job_title",
      operator: "contains",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Search Title",
      columns: 1,
      columnType: "string",
    },
    {
      title: "Company",
      field: "company_details.name",
      operator: "includes_by_id",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select Company",
      columns: 1,
      columnType: "autocomplete",
      searchUrl: "/company-dropdown",
      searchWithKey: "name",
      isMulti: true,
    },
    {
      title: "Posted On",
      field: "job_posted_at_datetime_utc",
      operator: "between",
      operators: [],
      query_1: null,
      query_2: null,
      placeholder: "Select Date",
      columns: 1,
      columnType: "datetime",
    },
  ]);

  const {
    loading: useFilterLoading,
    records,
    //selectedFilters,
    sortColumn,
    removeSortColumn,
    resetSorting,
    applyFilters,
    exportRecords,
    addFilter,
    clearFilter,
    removeFilter,
    resetFilter,
    onFilterColumnSelectHandler,
    onFilterOperatorSelectHandler,
    onFilterValueOneChangeHandler,
    onFilterValueTwoChangeHandler,
    updateCursor,
    error,
    buildQuery,

  } = useFilter({
    endpoint: companyId
      ? config.apiEndpoints.getFilteredJobs + "?company_id=" + companyId
      : config.apiEndpoints.getCompanyJobs,
    exportIdentifier: "Jobs",
    exportEndpoint: "/export/jobs",
    queryParams: [{ page: activePage }],
    filterArray: selectedFilters,
    selectedFilters: selectedFilters,
    setSelectedFilters: setSelectedFilters,
  });

  useEffect(()=>{
      if( companyId && records?.data?.length > 0){
        setSelectedFilters((prev :any)=>prev.map(( filter :any)=>{
               if(filter?.field == "company_id" ){
                return {...filter ,['query_1']:[{name:records?.data[0]?.company?.name,id:records?.data[0]?.company?._id}]}
               }
               return filter
        }));
      }
    },[records?.data?.length,companyId]);
  const handlePageChange = (pageNumber: number) => {
    setActivePage(pageNumber);
  };

  // const shortenedTitle = (title = "", limit = 200, showDotSeparator = true) => {
  //   if (title.length > limit) {
  //     const dots = showDotSeparator ? "..." : "";
  //     return title.substring(0, limit) + dots;
  //   }
  //   return title;
  // };

  const columns = [
    {
      name: "Title",
      render: (row: any) => (
        <div>
          {/* Job Title with Link */}
          <Link to={row.job_apply_link} target="_blank" rel="noopener noreferrer">
            <span className="fw-semibold">{row?.job_title || "--"}</span>
          </Link>
    
          {/* Description with Read More/Read Less */}
          <div className="mt-2">
            <span>
              {expandedRow === records?.data.indexOf(row)
                ? row.job_description
                : row.job_description.split(" ").slice(0, 10).join(" ")}
              {row.job_description.split(" ").length > 10 && (
                <>
                  <button
                    className="btn btn-link p-0 ms-1"
                    onClick={() =>
                      handleReadMoreToggle(records?.data.indexOf(row))
                    }
                  >
                    {expandedRow === records?.data.indexOf(row)
                      ? "Read Less"
                      : "Read More"}
                  </button>
                </>
              )}
            </span>
          </div>
        </div>
      ),
      sortable: true,
      key: "job_title",
      label: "Title",
      width: "60%",
    },
    
    {
      name: "Company Name",
      key: "company_id",
      label: "Company Name",
      render: (row: any) => row?.company?.name || "--"|| "--",
      sortable: true,
      width: "20%",
    },
    {
      name: "job_posted_at_datetime_utc",
      render: (row: any) => formatDate(row?.job_posted_at_datetime_utc) || "--",
      sortable: true,
      key: "job_posted_at_datetime_utc",
      label: "Posted On",
      width: "20%",
    },
  ];

  const handleRowClick = (detail: JobDetail) => {
    setSelectedData(detail);
  };

  useEffect(() => {
    if (records?.data?.length > 0) {
      setSelectedData({
        summary: records.data[0]?.job_description,
        url: records.data[0]?.job_apply_link,
        job_title: records.data[0]?.job_title,
      });
    } else {
      setSelectedData(null);
    }
  }, [records]);
  const fetchJobChartDetail = async () => {
    try {
      setJobChartLoading(true);
      let params :any = buildQuery();
       delete params?.page;
       delete params?.paginate;
      const response = await axios.get(
        `${config.apiEndpoints.getJobChart}`,
        {
          params: params,
        }
      );
      setJobChartData(response?.data?.data);
    } catch (error) {
    } finally {
      setJobChartLoading(false);
    }
  };
  useEffect(()=>{
    if(activeTab == 'charts'){
      fetchJobChartDetail();
    }
  },[activeTab,selectedFilters])

  return (
    <>
      <h3 className="fw-semibold font-s-20 text_purple mb-3">ESG Jobs</h3>
      <div>
        <section>
              <div className="companies_filter rounded px-4 pt-4 pb-3 bg-white shadow-sm">
                <Filterable
                  loading={useFilterLoading}
                  canExport={true}
                  showFilterable={showFilterable}
                  selectedFilters={selectedFilters}
                  sortColumn={sortColumn}
                  removeSortColumn={removeSortColumn}
                  resetSorting={resetSorting}
                  filterableFields={jobfilterableFields}
                  sortableFields={[]}
                  queryParams={[]}
                  exportRecords={exportRecords}
                  applyFilters={applyFilters}
                  clearFilter={clearFilter}
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                  resetFilter={resetFilter}
                  onFilterColumnSelectHandler={onFilterColumnSelectHandler}
                  onFilterOperatorSelectHandler={onFilterOperatorSelectHandler}
                  onFilterValueOneChangeHandler={onFilterValueOneChangeHandler}
                  onFilterValueTwoChangeHandler={onFilterValueTwoChangeHandler}
                  error={error}
                />
              </div>
        </section>
        <TabHeader activeTab={activeTab} 
         tabs={[
          { label: "Listing", value: "table" },
          { label: "Dashboard", value: "charts" },
        ].filter(Boolean)}
        onTabChange={setActiveTab} />

        {activeTab === "table" && (
          <div className="row">
            <div className={`col-lg-${table_view_column}`}>
              <section className="mt-5">
                <section className="mt-3 mt-lg-4">
                  <div className="row">
                    <div className="col-12">
                      <div className="card card-border mb-4 table_layout2">
                        <div className="position-relative">
                          <DataTable
                            loading={useFilterLoading}
                            data={records?.data || []}
                            columns={columns}
                            isBorderdTable={false}
                            emptyMessage={{
                              title: "No Jobs Found",
                            }}
                            sortColumn={sortColumn}
                            onRowClick={(row: any) =>
                              handleRowClick({
                                summary: row?.job_description,
                                url: row?.job_apply_link,
                                job_title: row?.job_title,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {config.paginator === "cursor" ? (
                    <div className="pagination">
                      {records?.meta?.has_previous ? (
                        <Link
                          to={""}
                          onClick={() =>
                            updateCursor(records.meta?.previous_token)
                          }
                          className="btn btn-primary"
                          style={{ marginRight: "5px" }}
                        >
                          Previous
                        </Link>
                      ) : (
                        <button
                          className="btn btn-secondary"
                          style={{ marginRight: "5px" }}
                          disabled
                        >
                          Previous
                        </button>
                      )}
                      {records?.meta?.has_next ? (
                        <Link
                          to={""}
                          onClick={() =>
                            updateCursor(records?.meta?.next_token)
                          }
                          className="btn btn-primary ml-1"
                        >
                          Next
                        </Link>
                      ) : (
                        <button className="btn btn-secondary" disabled>
                          Next
                        </button>
                      )}
                    </div>
                  ) : (
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={records?.total || 0}
                      pageRangeDisplayed={10}
                      itemClass="page-item"
                      linkClass="page-link"
                      onChange={handlePageChange}
                    />
                  )}
                </section>
              </section>
            </div>
            <div className={`col-lg-${details_view_column} ${details_class}`}>
              <div className="card card-border mt-4">
                <div className="card-header">Detail</div>
                <div className="card-body">
                  {useFilterLoading ? (
                    <Skeleton height={200} width={"100%"} />
                  ) : selectedData?.summary ? (
                    <div>
                      <h3>
                        <Link
                          title="Link"
                          className="mt-5"
                          to={selectedData?.url || ""}
                          target="_blank"
                        >
                          <span className="fw-semibold">
                            {selectedData.job_title}
                          </span>
                        </Link>
                      </h3>
                      <p>
                        {readMore
                          ? selectedData?.summary
                          : selectedData?.summary.substring(0, 200)}
                        ...
                        {selectedData?.summary?.length > 200 && (
                          <Link
                            to={""}
                            className="mt-5"
                            onClick={(): void => setReadmore(!readMore)}
                          >
                            {readMore ? "Read Less" : "Read More"}
                          </Link>
                        )}
                      </p>
                    </div>
                  ) : (
                    <p>Select a job to see details here.</p>
                  )}
                  {/* {selectedData?.url && (
                    <div className="text-end">
                      <Link
                        className="mt-5"
                        to={selectedData?.url || ""}
                        target="_blank"
                      >
                        View Job Detail
                      </Link>
                    </div>
                  )} */}
                  <div className="text-end mt-3">
                    <span className="border border-1 border-primary rounded py-1 px-2">
                      <Link
                        title="Link"
                        className="mt-5"
                        to={selectedData?.url || ""}
                        target="_blank"
                      >
                        <LinkIcon />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeTab === "charts" && (
          <div className="row">
            <div className="col-lg-12 mt-3">
              <div className="card card-border mb-4">
                <div className="card-body">
                  {JobChartLoading ? (
                    <Skeleton height={300} width="100%" className="mb-2" />
                  ) : (
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={{
                        chart: {
                          type: "line",
                        },
                        title: {
                          text: "",
                        },
                        xAxis: {
                          categories: JobChartData?.categories.slice(0,chartKeywordLimit)
                        },

                        yAxis: {
                          title: {
                            text: JobChartData?.ytitle
                          }
                        },
                        legend: {
                          layout: 'vertical',
                          align: 'right',
                          verticalAlign: 'top',
                        },
                        exporting: {
                          enabled: false
                        },
                        credits: {
                          enabled: false
                        },
                        series: JobChartData?.series.map((sr:any)=>{
                          return {...sr,['data']:sr?.data.slice(0,chartKeywordLimit)}
                        }),

                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Jobs;
