import { Link } from "react-router-dom";
import thumbnailImage from "../../assets/img/thumbnailImage.png";
import Charts from "../../components/common/Charts/Charts";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { useState } from "react";

import MaleFemaleProgressBar from "../../components/common/Charts/MaleFemaleProgressBar";
import * as funnel from "highcharts/modules/funnel";
import GenderGapChart from "../../components/common/Charts/GenderGapChart";
import AntiCoruptionChart from "../../components/common/Charts/AntiCoruptionChart";
console.log(funnel); //on removing it giving error for the importing the funnel module

const Dataset = () => {
  //eslint-disable-next-line
  const [xAxis, setXAxis] = useState([]);
  const plotOptions = {
    pie: {
      allowPointSelect: true,
      cursor: "pointer",
      borderWidth: 0, // Hide the border

      dataLabels: {
        enabled: false,
        format: "{point.name}: {y} %",
      },
      showInLegend: false,
      startAngle: -90,
      endAngle: 90,
      center: ["50%", "75%"],
      size: "110%",
    },
  };
  const patentPointFormatter = () => {
    return `<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b>`;
  };

  return (
    <>
      <section>
        <div className="row mt-4">
          <div className="col-lg-4 col-md-6">
            <div className="border bg-info bg-opacity-10 rounded p-4">
              <h5 className="text-black fw-semibold mb-3">Patents</h5>
              {/* <div className="text-center">
                <img src={thumbnailImage} alt="img" />
              </div> */}
              <Charts
                title={""}
                type={"area"}
                XAxis={[]}
                showLegend={false}
                height={"200px"}
                // seriesData={patentData}
                seriesData={[
                  {
                    name: "USA",
                    data: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      2,
                      9,
                      13,
                      50,
                      170,
                      299,
                      438,
                      841,
                      1169,
                      1703,
                      2422,
                      3692,
                      5543,
                      7345,
                      12298,
                      18638,
                      22229,
                      25540,
                      28133,
                      29463,
                      31139,
                      31175,
                      31255,
                      29561,
                      27552,
                      26008,
                      25830,
                      26516,
                      27835,
                      28537,
                      27519,
                      25914,
                      25542,
                      24418,
                      24138,
                      24104,
                      23208,
                      22886,
                      23305,
                      23459,
                      23368,
                      23317,
                      23575,
                      23205,
                      22217,
                      21392,
                      19008,
                      13708,
                      11511,
                      10979,
                      10904,
                      11011,
                      10903,
                      10732,
                      10685,
                      10577,
                      10526,
                      10457,
                      10027,
                      8570,
                      8360,
                      7853,
                      5709,
                      5273,
                      5113,
                      5066,
                      4897,
                      4881,
                      4804,
                      4717,
                      4571,
                      4018,
                      3822,
                      3785,
                      3805,
                      3750,
                      3708,
                      3708,
                      3708,
                      3708,
                    ],
                  },
                  {
                    name: "USSR/Russia",
                    data: [
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      null,
                      1,
                      5,
                      25,
                      50,
                      120,
                      150,
                      200,
                      426,
                      660,
                      863,
                      1048,
                      1627,
                      2492,
                      3346,
                      4259,
                      5242,
                      6144,
                      7091,
                      8400,
                      9490,
                      10671,
                      11736,
                      13279,
                      14600,
                      15878,
                      17286,
                      19235,
                      22165,
                      24281,
                      26169,
                      28258,
                      30665,
                      32146,
                      33486,
                      35130,
                      36825,
                      38582,
                      40159,
                      38107,
                      36538,
                      35078,
                      32980,
                      29154,
                      26734,
                      24403,
                      21339,
                      18179,
                      15942,
                      15442,
                      14368,
                      13188,
                      12188,
                      11152,
                      10114,
                      9076,
                      8038,
                      7000,
                      6643,
                      6286,
                      5929,
                      5527,
                      5215,
                      4858,
                      4750,
                      4650,
                      4600,
                      4500,
                      4490,
                      4300,
                      4350,
                      4330,
                      4310,
                      4495,
                      4477,
                      4489,
                      4380,
                    ],
                  },
                ]}
                pointStart={2000}
                pointFormatter={patentPointFormatter}
              />
              <div className="text-end mt-2">
                <Link
                  to="/dataset/patent"
                  className="text-decoration-underline"
                >
                  Show More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
            <div className="border bg-info bg-opacity-10 rounded p-4">
              <h5 className="text-black fw-semibold mb-3">Deals</h5>
              {/* <div className="text-center">
                <img src={thumbnailImage} alt="img" />
              </div> */}

              <div>
                {/* <Charts
                  type="column"
                  title="Corn vs Wheat Estimated Production for 2023"
                  plotOptions={{
                    column: {
                      pointPadding: 0.2,
                      borderWidth: 0,
                    },
                  }}
                  height="300px"
                  seriesData={[
                    {
                      name: "Corn",
                      data: [387749, 280000, 129000, 64300, 54000, 34300],
                    },
                    {
                      name: "Wheat",
                      data: [45321, 140000, 10000, 140500, 19500, 113500],
                    },
                  ]}
                  XAxis={["USA", "China", "Brazil", "EU", "Argentina", "India"]}
                /> */}

                {/* esg risk rating column chart with all type of status*/}
                {/* <Charts
                  type="column"
                  title="Rating"
                  yAxis={{
                    title: {
                      text: "Scale",
                    },
                    tickInterval: 10, // Set intervals to 10
                    min: 0, // Start scale from 0
                    max: 30, // End scale at 30
                  }}
                  height="300px"
                  seriesData={[
                    {
                      data: [15, 25, 10, 30, 20],
                    },
                    {
                      data: [15, 25, 10, 30, 20],
                    },
                    {
                      data: [15, 25, 10, 30, 20],
                    },
                    {
                      data: [15, 25, 10, 30, 20],
                    },
                  ]}
                  XAxis={["Neglible", "Low", "Medium", "High", "Severe"]}
                  showLegend={false}
                /> */}

                {/* <Charts
                  type="pie"
                  seriesData={[
                    {
                      name: "Chrome",
                      y: 61.04,
                      color: "#418c9c",
                      drilldown: "chrome",
                    },
                    {
                      name: "Safari",
                      y: 9.47,
                      color: "#233b7d",
                      drilldown: "safari",
                    },
                    {
                      name: "Edge",
                      y: 9.32,
                      color: "#7d233b",
                      drilldown: "edge",
                    },
                    {
                      name: "Firefox",
                      y: 8.15,
                      color: "#3b7d23",
                      drilldown: "firefox",
                    },
                    { name: "Other", y: 11.02, color: "#7d3b23" },
                  ]}
                  drilldownData={[
                    {
                      id: "chrome",
                      data: [
                        ["v97.0", 36.89],
                        ["v96.0", 18.16],
                      ],
                    },
                    {
                      id: "safari",
                      data: [
                        ["v15.3", 2.01],
                        ["v15.2", 1.47],
                      ],
                    },
                    {
                      id: "edge",
                      data: [
                        ["v97", 6.62],
                        ["v96", 2.55],
                      ],
                    },
                    {
                      id: "firefox",
                      data: [
                        ["v96.0", 4.17],
                        ["v95.0", 3.33],
                      ],
                    },
                  ]}
                  height="400px"
                  title="Browser Market Share"
                /> */}

                {/* esg risk rating column chart */}
                <HighchartsReact
                  highcharts={Highcharts}
                  options={{
                    chart: {
                      type: "column",
                    },
                    title: {
                      text: "Rating",
                    },
                    xAxis: {
                      categories: ["Low"],
                      crosshair: true,
                      accessibility: {
                        description: "ESG Rating",
                      },
                    },
                    yAxis: {
                      min: 0,
                      max: 100,
                      tickInterval: 20,
                    },
                    plotOptions: {
                      column: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                      },
                    },
                    series: [
                      {
                        name: "E",
                        data: [80],
                      },
                      {
                        name: "S",
                        data: [60],
                      },
                      {
                        name: "G",
                        data: [70],
                      },
                      {
                        name: "Overall",
                        data: [75],
                      },
                    ],
                  }}
                />
              </div>
              <div className="text-end mt-2">
                <Link to="dataset/deals" className="text-decoration-underline">
                  Show More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="border bg-info bg-opacity-10 rounded p-4">
              <h5 className="text-black fw-semibold mb-3">Social Media</h5>
              {/* <div className="text-center">
                <img src={thumbnailImage} alt="img" />
              </div> */}
              <Charts
                type="pie"
                title={""}
                showPercentage={false}
                plotOptions={plotOptions}
                height={"200px"}
                seriesData={[
                  { name: "Environment", y: 30, color: "red" },
                  { name: "Social", y: 40, color: "yellow" },
                  { name: "Governance", y: 30, color: "green" },
                ]}
              />

              <div className="text-end mt-2">
                <Link
                  to="/dataset/social-media"
                  className="text-decoration-underline"
                >
                  Show More
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-4 col-md-6">
            <div className="border bg-info bg-opacity-10 rounded p-4">
              <h5 className="text-black fw-semibold mb-3">News</h5>
              <div className="d-flex align-items-center">
                <img src={thumbnailImage} alt="img" />
                <div className="ms-3">
                  <h6 className="fw-semibold text-black">
                    30 Thrilling Disaster Movies To Send You...
                  </h6>
                  <p>Explore top disaster movies, featuring epic natura...</p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <img src="img/thumbnailImage.png" alt="img" />
                <div className="ms-3">
                  <h6 className="fw-semibold text-black">
                    Tax Breaks: The There’s No Crying In Bas...
                  </h6>
                  <p>Our latest edition of Tax Breaks. Plus: Hurricane ...</p>
                </div>
              </div>
              <div className="text-end mt-2">
                <Link to="/dataset/news" className="text-decoration-underline">
                  Show More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mt-md-0">
            <div className="border bg-info bg-opacity-10 rounded p-4">
              <h5 className="text-black fw-semibold mb-3">Job</h5>
              <div className="d-flex align-items-center">
                <img src={thumbnailImage} alt="img" />
                <div className="ms-3">
                  <h6 className="fw-semibold text-black">
                    Advanced Practice Clinician - Dermatolog...
                  </h6>
                  <p>Optum Tri-State – CareMount Medical, part of the O...</p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <img src="img/thumbnailImage.png" alt="img" />
                <div className="ms-3">
                  <h6 className="fw-semibold text-black">
                    CA Physician Pathways Program | Optum CA
                  </h6>
                  <p>Optum CA part of the Optum family of businesses, i...</p>
                </div>
              </div>
              <div className="text-end mt-2">
                <Link to="/dataset/jobs" className="text-decoration-underline">
                  Show More
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mt-4 mt-lg-0">
            <div className="border bg-info bg-opacity-10 rounded p-4">
              <h5 className="text-black fw-semibold mb-3">Regulations</h5>
              <div className="d-flex align-items-center">
                <img src={thumbnailImage} alt="img" />
                <div className="ms-3">
                  <h6 className="fw-semibold text-black">
                    Wages and the Fair Labor Standards Act
                  </h6>
                  <p>The Fair Labor Standards Act (FLSA) establishes mi...</p>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <img src="img/thumbnailImage.png" alt="img" />
                <div className="ms-3">
                  <h6 className="fw-semibold text-black">
                    The Emergency Planning and Community Rig...
                  </h6>
                  <p>The Emergency Planning and Community Right-to-Know...</p>
                </div>
              </div>
              <div className="text-end mt-2">
                <Link
                  to="/dataset/regulations"
                  className="text-decoration-underline"
                >
                  Show More
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* GHC chart */}
        <HighchartsReact
          height="2000px"
          highcharts={Highcharts}
          options={{
            chart: {
              type: "area",
            },
            title: {
              text: "GHG Emissions Trend",
            },
            // subtitle: {
            //   text: "Source: Example Data",
            // },
            xAxis: {
              categories: ["1990", "2000", "2010", "2019"],
              tickmarkPlacement: "on",
              title: {
                text: null,
              },
              gridLineWidth: 2, // Adds vertical gridlines between intervals
              gridLineColor: "grey",
            },
            yAxis: {
              title: {
                text: "GHG Emissions (Gt CO₂-eq)",
              },
              labels: {
                format: "{value} Gt",
              },
              min: 0,
              tickInterval: 10,
              max: 100,
            },
            tooltip: {
              shared: true,
              valueSuffix: " Gt",
            },
            plotOptions: {
              area: {
                stacking: "normal",
                lineColor: "#666666",
                lineWidth: 1,
                marker: {
                  enabled: false,
                },
                dataLabels: {
                  enabled: true,
                  formatter: function (this: any): string {
                    // Now TypeScript understands `this` is a `Point` object
                    return this.y + "%"; // Add percentage symbol, or format as needed
                  },
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "black", // Customize color
                    marginLeft: "5px",
                  },
                  verticalAlign: "middle", // Position the label vertically
                  align: "left", // Align the label horizontally
                  y: -10,
                  x: 10,
                },
              },
            },
            series: [
              {
                name: "Energy Production",
                data: [13, 17, 16, 18],
              },
              {
                name: "Transport",
                data: [10, 11, 14, 16],
              },
              {
                name: "Industry",
                data: [7, 8, 9, 10],
              },
              {
                name: "Agriculture",
                data: [8, 7, 7, 6],
              },
              {
                name: "Residential",
                data: [4, 5, 5, 5],
              },
              {
                name: "Production",
                data: [13, 17, 16, 18],
              },
              {
                name: "Gaming",
                data: [10, 11, 14, 16],
              },
              {
                name: "Play",
                data: [7, 8, 9, 10],
              },
            ],
          }}
        />
        {/* water */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "pie",
              height: "400px",
            },
            title: {
              text: "Water",
            },
            legend: {
              enabled: true, // Enable the legend
              layout: "horizontal", // Vertical layout for the legend
              align: "right", // Align the legend to the right
              verticalAlign: "right", // Vertically center the legend
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: true,
                  formatter: function (this: any): string {
                    return `<b>${this.y}</b>`;
                  },
                  style: {
                    fontSize: "12px",
                    fontWeight: "bold",
                    color: "black",
                  },
                  verticalAlign: "middle", // Position the label vertically
                  align: "left", // Align the label horizontally
                  y: -10,
                  x: 10,
                },
              },
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            series: [
              {
                name: "Water Withdrawal",
                data: [
                  { name: "Circle 1", y: 1000, color: "#f28e2c" },
                  { name: "circle inner", y: 250, color: "blue" },
                  { name: "circle inner 2", y: 70, color: "green" }, // Transparent spacer to fill the circle
                ],
                size: "80%",
                innerSize: "60%",
              },
              {
                name: "Water Consumption",
                data: [
                  { name: "Circle 2", y: 975, color: "#f28e2c" },
                  { name: "circle inner", y: 260, color: "blue" },
                  { name: "circle inner 2", y: 100, color: "green" }, // Transparent spacer
                ],
                size: "60%",
                innerSize: "40%",
              },
              {
                name: "Water Discharge",
                data: [
                  { name: "Circle 3", y: 1020, color: "#f28e2c" },
                  { name: "circle inner", y: 300, color: "blue" },
                  { name: "circle inner 2", y: 150, color: "green" }, // Transparent spacer
                ],
                size: "40%",
                innerSize: "20%",
              },
            ],
          }}
        />
        {/* turn over rate chart */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "column",
            },
            title: {
              text: "Turnover Rate",
              align: "left",
            },
            xAxis: {
              categories: [
                "Jan 2023",
                "Feb 2023",
                "Mar 2023",
                "Apr 2023",
                "May 2023",
                "Jun 2023",
              ],
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Turnover Count",
              },
              stackLabels: {
                enabled: false, //make it true for showing data on columns
                style: {
                  fontWeight: "bold",
                  color: "gray",
                },
              },
            },
            // tooltip: {
            //   headerFormat: "<b>{point.x}</b><br/>",
            //   pointFormat:
            //     "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
            // },
            plotOptions: {
              column: {
                stacking: "normal",
                dataLabels: {
                  enabled: false, //make it true for showing data in columns
                  style: {
                    fontWeight: "bold",
                    color: "black",
                  },
                },
              },
            },
            series: [
              {
                name: "Voluntary",
                data: [60, 50, 55, 60, 65, 70],
                color: "#8B4513", // Brown color
              },
              {
                name: "Involuntary",
                data: [30, 25, 25, 30, 35, 40],
                color: "#D2B48C", // Beige color
              },
            ],
          }}
        />

        {/* male and female pie */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "pie",
            },
            title: {
              text: "Male and Female Proportion",
              align: "center",
            },
            tooltip: {
              pointFormat: "<b>{point.y}%</b> ({point.name})",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "{point.name}: {point.y}%",
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                  },
                },
                showInLegend: true,
              },
            },
            series: [
              {
                name: "Proportion",
                colorByPoint: true,
                data: [
                  {
                    name: "Male",
                    y: 45,
                    color: "#4CAF50", // Blue for males
                  },
                  {
                    name: "Female",
                    y: 55,
                    color: "#FF4081", // Pink for females
                  },
                ],
              },
            ],
          }}
        />

        {/* Average Training Hours */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "column",
            },
            title: {
              text: "Average Training Hours",
              align: "left",
            },
            xAxis: {
              categories: ["Jan 2023", "Feb 2023", "Mar 2023"],
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Turnover Count",
              },
              tickInterval: 10,
              max: 60,
            },
            plotOptions: {
              column: {
                stacking: null,
                dataLabels: {
                  enabled: true, //make it true for showing data in columns
                  style: {
                    fontWeight: "bold",
                    color: "black",
                  },
                  verticalAlign: "bottom",
                  y: 20, // Adjust label position
                },
              },
            },
            series: [
              {
                name: "Male",
                data: [42, 43, 46],
                color: "#a8c8df",
              },
              {
                name: "Female",
                data: [40, 41, 42],
                color: "#d9afdd",
              },
            ],
          }}
        />

        {/* Female Representation on Boards Over Time */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "pie",
            },
            title: {
              text: "Female Representation on Boards Over Time",
              align: "center",
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: true,
                  format: "{point.y}%", // Display percentage inside the pie slice
                  style: {
                    fontWeight: "bold",
                    fontSize: "14px",
                  },
                  distance: -120, // Position the label inside the chart
                },
                innerSize: "60%", // Creates a donut chart
                size: "70%", // Adjusts the overall size of the pie
              },
            },
            series: [
              {
                name: "2021",
                data: [
                  { name: "2021", y: 15, color: "#FFD700" },
                  { name: "2021", y: 85, color: "#eddb7b" },
                ], // Yellow
                center: ["20%", "50%"], // Adjust position for the first pie
              },
              {
                name: "2022",
                data: [
                  { name: "2022", y: 20, color: "#FFA07A" },
                  { name: "2022", y: 80, color: "#e04a0d" },
                ], // Orange
                center: ["50%", "50%"], // Adjust position for the second pie
              },
              {
                name: "2023",
                data: [
                  { name: "2023", y: 23, color: "#FF69B4" },
                  { name: "2023", y: 76, color: "#ab0759" },
                ], // Pink
                center: ["80%", "50%"], // Adjust position for the third pie
              },
            ],
          }}
        />

        {/* Fatalities */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "bar",
            },
            title: {
              text: "Fatalities",
              align: "center",
            },
            xAxis: {
              categories: [
                "Manual handling (internal injury)",
                "Slipping, falling",
                "Loss of control of object, machine, vehicle, etc.",
                "Aggression, shock, violence",
                "Body movement leads to cut, bruise (external injury)",
              ],
              title: {
                text: null,
              },
              gridLineWidth: 2, // Adds vertical gridlines between intervals
              gridLineColor: "grey",
            },
            yAxis: {
              min: 0,
              title: {
                text: "Number of Fatalities",
                align: "high",
              },
              labels: {
                overflow: "justify",
              },
            },
            plotOptions: {
              bar: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            legend: {
              reversed: true,
            },
            series: [
              {
                name: "2019",
                data: [2800, 2400, 1400, 900, 400],
                color: "#FFD700", // Yellow
              },
              // {
              //   name: "Average 2015-2019",
              //   data: [3000, 2600, 1200, 800, 300],
              //   color: "#87CEFA", // Light blue
              // },
            ],
          }}
        />

        {/* Total Annual Waste Generation */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              renderTo: "container",
              type: "column",
            },
            title: {
              text: "Total Annual Waste Generation",
            },
            tooltip: {
              shared: true,
            },
            xAxis: {
              categories: ["2011", "2013", "2020", "2021", "2023"],
              crosshair: true,
            },
            yAxis: [
              {
                title: {
                  text: "Waste Generated (in tons)",
                },
              },
            ],
            series: [
              {
                name: "Waste Generated",
                type: "column",
                data: [1100, 1900, 2200, 2500, 2600],
                color: "#00BFFF", // Light blue for the columns
              },
              {
                name: "Trend Line",
                type: "line",
                data: [400, 800, 1300, 2200, 2600],
                color: "#FFA500", // Orange for the trend line
                marker: {
                  enabled: true,
                },
              },
            ],
          }}
        />

        {/* Energy Consumption Chart */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "pie",
            },
            title: {
              text: "Energy Consumption Overview",
              align: "center",
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: true,
                  useHTML: true,
                  formatter: function (this: any): string {
                    // Render icons with HTML
                    if (this.point.name === "Renewable Energy Consumption") {
                      return `
                        <div style="text-align: center;">
                          <img src="https://img.icons8.com/ios-filled/50/4caf50/battery.png" width="40" height="40"  style="margin-bottom: 5px; filter: brightness(0) invert(1);" />
                          <br>${this.point.name}
                        </div>`;
                    } else if (this.point.name === "Total Energy Consumption") {
                      return `
                        <div style="text-align: center;" >
                          <img src="https://img.icons8.com/ios-filled/50/03a9f4/electrical.png" width="40" height="40"  style="margin-bottom: 5px; filter: brightness(0) invert(1);" />
                          <br>${this.point.name}
                        </div>`;
                    } else if (this.point.name === "Center") {
                      return `
                        <div style="text-align: center;" >
                         <span style="font-size: 25px; font-weight: bold;">10</span>
                          <img src="https://img.icons8.com/?size=100&id=37778&format=png&color=000000" width="50" height="50"  style="margin-bottom: 5px; filter: brightness(0) invert(1);" />
                        </div>`;
                    }
                    return "";
                  },
                  style: {
                    textAlign: "center",
                    fontSize: "12px",
                  },
                  distance: -60, // Bring labels closer to the center
                },
                borderWidth: 1,
                showInLegend: true,
              },
            },
            series: [
              // Outer Circle
              {
                name: "Energy Consumption",
                size: "120%",
                innerSize: "30%",
                data: [
                  {
                    name: "Renewable Energy Consumption",
                    y: 50,
                    color: "#4CAF50", // Green
                  },
                  {
                    name: "Total Energy Consumption",
                    y: 50,
                    color: "#03A9F4", // Blue
                  },
                ],
              },
              // Inner Circle
              {
                name: "Inner Circle",
                size: "40%",
                innerSize: "0%",
                data: [
                  {
                    name: "Center",
                    y: 100,
                    color: "#666666", // Light Gray for Center
                    dataLabels: {
                      enabled: true,
                    },
                  },
                ],
              },
            ],
          }}
        />

        {/* Employee Classification Funnel Chart */}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              type: "funnel",
            },
            title: {
              text: "Employee Classification Funnel",
            },
            plotOptions: {
              series: {
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b> ({point.y:,.0f})",
                  softConnector: true,
                },
                // Set the size and proportions of the funnel
                center: ["50%", "50%"], // Center the funnel
                width: "50%", // Set the width of the funnel
                height: "80%", // Set the height of the funnel
                neckWidth: "20%", // Set the neck width
                neckHeight: "30%", // Set the neck height
              },
            },
            legend: {
              enabled: false,
            },
            series: [
              {
                name: "Unique users",
                data: [
                  ["Permanent Employees", 10000],
                  ["Temporary Employees", 4064],
                ],
              },
            ],
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    plotOptions: {
                      series: {
                        dataLabels: {
                          inside: true,
                        },
                        center: ["50%", "50%"],
                        width: "80%",
                        height: "60%",
                      },
                    },
                  },
                },
              ],
            },
          }}
        />

        {/* Total Recordable Incident Rate (TRIR)*/}
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            chart: {
              plotBackgroundColor: null,
              plotBorderWidth: 0,
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: `TRIR <br/>${3}`,
              align: "center",
              verticalAlign: "middle",
              y: 60,
              style: {
                fontSize: "1.1em",
              },
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.y}</b>",
            },
            accessibility: {
              point: {
                valueSuffix: "",
              },
            },
            plotOptions: {
              pie: {
                dataLabels: {
                  enabled: true,
                  distance: -50,
                  style: {
                    fontWeight: "bold",
                    color: "white",
                  },
                },
                startAngle: -90,
                endAngle: 90,
                center: ["50%", "75%"],
                size: "110%",
              },
            },
            series: [
              {
                name: "Values",
                innerSize: "50%",
                data: [
                  {
                    name: "Filled",
                    y: 3,
                    color: "#007bff", // Blue color for the filled portion
                  },
                  {
                    name: "Remaining",
                    y: 6 - 3,
                    color: "#f0f0f0", // White color for the remaining portion
                  },
                ],
              },
            ],
          }}
        />

        {/* Male female proportion chart*/}
        <div className="mt-5">
          <h5 className="text-black">Male female proportion chart</h5>
          <MaleFemaleProgressBar score={83} isFemaleIcon={false} />
          <MaleFemaleProgressBar score={43} isFemaleIcon={true} />
          <br />
        </div>

        {/* Gender Gap chart*/}
        <div>
          <h5 className="text-black">Gender Gap chart</h5>
          <GenderGapChart score={55} />
        </div>

        <div className="mt-5">
          <AntiCoruptionChart />
        </div>
      </section>
    </>
  );
};
export default Dataset;
