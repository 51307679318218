import React from "react";
import { ReactComponent as MaleIcon } from "../../../assets/img/male-svgrepo-com.svg";
import { ReactComponent as FemaleIcon } from "../../../assets/img/female-svgrepo-com.svg";

type IconFillerProps = {
  score: number; // Score between 0 and 100
  isFemaleIcon?: boolean; // The SVG icon component
};

const MaleFemaleProgressBar: React.FC<IconFillerProps> = ({
  score,
  isFemaleIcon = false,
}) => {
  const totalIcons = 10; // Total number of icons
  const scorePerIcon = 100 / totalIcons; // Score each icon represents
  const filledIcons = Math.floor(score / scorePerIcon); // Fully filled icons
  const partialFill = (score % scorePerIcon) / scorePerIcon; // Partial fill for the last icon

  const Icon = isFemaleIcon ? FemaleIcon : MaleIcon;
  const fillColor = isFemaleIcon ? "#ff69b4" : "#007bff";

  return (
    <div style={{ display: "flex", gap: "1px" , justifyContent : "center"}}> 
      <span
        style={{
          marginLeft: "10px",
          marginTop: "10px",
          textAlign: "center",
          fontSize: "20px",
        }}
      >{`${score}%`}</span>
      {Array.from({ length: totalIcons })?.map((_, index) => {
        const isFilled = index < filledIcons;
        const isPartial = index === filledIcons;

        return (
          <div
            key={index}
            style={{
              position: "relative",
              width: "50px",
              height: "50px",
              overflow: "hidden",
            }}
          >
            <Icon
              style={{
                fill: isFilled
                  ? fillColor // Fully filled color
                  : isPartial
                  ? `url(#partial-gradient-${index})` // Partial fill
                  : "#d3d3d3", // Empty color
                width: "100%",
                height: "100%",
              }}
            />
            {isPartial && (
              <svg width="0" height="0">
                <defs>
                  <linearGradient
                    id={`partial-gradient-${index}`}
                    x1="0%"
                    y1="0%"
                    x2="100%"
                    y2="0%"
                  >
                    <stop
                      offset={`${partialFill * 100}%`}
                      stopColor={fillColor}
                    />
                    <stop
                      offset={`${partialFill * 100}%`}
                      stopColor="#f0f0f0"
                    />
                  </linearGradient>
                </defs>
              </svg>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MaleFemaleProgressBar;
