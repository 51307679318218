const config = {
  isProduction: process.env.NODE_ENV === "production",
  isDevelopment: process.env.NODE_ENV !== "production",
  title: process.env.REACT_APP_TITLE,
  apiBase: process.env.REACT_APP_API_URL,
  authRedirect: "/login",
  authSuccessRedirect: "/",
  apiEndpoints: {
    login: "/sign-in",
    signUp: "/signup",
    verifyOtp: "/verify-otp",
    resendOtp: "/resend-otp",
    forgotPassword: "/forgot-password",
    updatePassword: "/update-password",
    updateUser: "/update-user",
    changePassword: "/change-password",
    getCurrentUser: "/users/get-current-user",
    otp: "/login/validate",
    profile: "/me",
    editProfile: "/profile/update",
    bookmark: "/bookmarks/update-status",
    createJob: "/job/create",
    createUser: "/user/create",
    createDial: "/dial/create",
    companies: "/companies",
    regulationList: "/regulations",
    patents: "/patents",
    getFilteredPatents: "/get-filtered-patents",
    getNews: "/get-news",
    getCompanyNews: "/get-company-news",
    getAllNews: "/get-all-news",
    getRegulations: "/regulations",
    getFilteredRegulations: "/get-filtered-regulations",
    getCompanyJobs: "/get-company-jobs",
    getFilteredJobs: "/get-filtered-jobs",
    contactUs: "/contact-us",
    recentVisited: "/recent-visit",
    getCompanyDetail: "/company",
    getPatentDetail: "/patents",
    getJobDetail: "/company-jobs",
    getCompanyEntityCounts: "/get-entity-counts",
    getBookmarks: "/get-bookmarks",
    getGroupedJobs: "/get-grouped-jobs",
    getDealsAndInvestmentDetails: "get-company-deals",
    getIndustries: "/industries",
    getCompanyDropdown: "company-dropdown",
    getIndustryESGScore: "industry-esg-score",
    getESGRiskRatingChart: "get-esg-risk-rating-chart",
    getGHGEmissionChart: "get-ghg-emission-chart",
    getWGChart: "get-waste-generation-chart",
    getAvgTrainingHours:"get-average-traininig-chart",
    getWaterConsumptionChart: "/get-water-consumption-chart",
    getTRIRAccidentRateChart: "/get-trir-accident-rate-chart",
    getECChart: "/get-employee-classification-chart",
    getFatilitiesChart: "/get-fatalities-chart",
    getFemaleRepresentationOnBorardChart: "/get-female-representation-on-board-chart",
    getAntiCorruptionChart: "/get-anti-corruption-chart",
    getEmployeeTurnover: "/get-employee-turnover-chart",
    getEnergyChart: "/get-energy-chart",
    getMaleFemaleProportionChart: "/get-male-female-proportion-chart",
    getGenderPayGapChart: "/get-gender-pay-gap-chart",
    getPatentChart: "/get-patent-chart",
    getJobChart: "/get-job-chart",
    getNewsChart: "/get-news-chart",
    getRegulationsChart: "/get-regulations-chart",
    getCompanyCharts: "/get-company-charts",
  },
  paginator: "cursor",
  chart:"charts"
};

export default config;
