import Anti1 from "../../../assets/img/anti1.png";
import Anti2 from "../../../assets/img/anti2.png";
import Anti3 from "../../../assets/img/anti3.png";
import "./AntiCoruption.css";

const AntiCoruptionChart = ({antiCurroptionData}:any) => {
  const images = [Anti1,Anti2,Anti3];
  return (
    <section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <h4 className="text-center text-black  my-4  chart-title">
              Rising Trend in Anti-Corruption Cases
            </h4>
            <ul className="timeline_1">
              {
                antiCurroptionData && antiCurroptionData?.map((data: any, index: number) => {
                  let img = antiCurroptionData[index]?.value > antiCurroptionData[index-1]?.value ? Anti2 :Anti3;
                  if (index == 0) {
                    return (
                      <li className="timeline_item">
                        <div className="d-flex align-items-center position-relative">
                          <div className="me-3 text-end">
                            <h5>{data?.year}</h5>
                            <p>{data?.value} casesreported</p>
                          </div>
                          <div>
                            <img className="chart_image_1" src={Anti1} width="60px" height="60px" alt="anti1" />
                          </div>
                        </div>
                      </li>
                    )
                  }
                  if (index % 2 != 0) {
                    return (
                      <li className="timeline_item mt-3 mt-lg-5">
                        <div className="d-flex align-items-center position-relative padding_227">
                          <div>
                            <img className="chart_image_2" src={img } width="60px" height="60px" alt="anti2" />
                          </div>
                          <div className="ms-3">
                          <h5>{data?.year}</h5>
                          <p>{data?.value} casesreported</p>
                          </div>
                        </div>
                      </li>
                    )
                  } else {
                    return (
                      <li className="timeline_item mt-3 mt-lg-5">
                        <div className="d-flex align-items-center position-relative">
                          <div className="me-3 text-end">
                          <h5>{data?.year}</h5>
                          <p>{data?.value} casesreported</p>
                          </div>
                          <div>
                            <img className="chart_image_3" src={img} width="60px" height="60px" alt="anti3" />
                          </div>
                        </div>
                      </li>
                    )
                  }
                })
              }


            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AntiCoruptionChart;
