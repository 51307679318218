import React, { Fragment, useEffect, useState } from "react";
import config from "../../config";
import { Link, useParams } from "react-router-dom";
import { useAuthenticatedAxios } from "../../hooks/useAxios";
import {
  Badge,
  currencyToSymbolConversion,
  formatCurrency,
  formatDate,
} from "../../components/common/CommonUtils";

import DataTable from "../../components/common/DataTable";
import TabHeader from "../../components/common/TabHeader";
import Skeleton from "react-loading-skeleton";
import { Button, Modal } from "react-bootstrap";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import CompanyCharts from "./CompanyCharts";
import ScoreBlocks from "../../components/common/ProgressSegmentBar";

const CompanyDetail = () => {
  const { id = "" } = useParams();
  const axios = useAuthenticatedAxios();
  // const navigate = useNavigate();
  const [companyDetails, setCompanyDetails] = useState<any>("");
  const [newsDetails, setNewsDetails] = useState<any>("");
  const [patentDetails, setPatentsDetails] = useState<any>("");
  const [regulationsDetails, setRegulationsDetails] = useState<any>("");
  const [jobDetails, setJobDetails] = useState<any>("");
  const [ecgRiskRatingDetails, setESGRiskRatingChart] = useState<any>("");

  const [dealsAndInvestmentDetails, setDealsAndInvestmentDetails] =
    useState<any>("");
  // const [investmentDetails, setInvestmentDetails] = useState<any>("");
  const [companyLoading, setCompanyLoading] = useState(false);
  const [patentLoading, setPatentLoading] = useState(false);
  const [regulationLoading, setRegulationLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [ECGRiskRatingLoading, setecgRiskRatingLoading] = useState(false);

  const [newsLoading, setNewsLoading] = useState(false);
  const [dealAndInvestmentLoading, setDealAndInvestmentLoading] =
    useState(false);
  const [activeTab, setActiveTab] = useState("overview");
  const [hasFinancial, setHasFinancial] = useState(false);
  const [companyList, setCompanyList] = useState<any>([]);
  const [relatedCompanyLoading, setRelatedCompanyLoading] = useState(false);
  const [industryId, setIndustryId] = useState("");
  const [showOverviewModal, setShowOverviewModal] = useState(false);

  const fetchCompanyNewsDetails = async () => {
    try {
      setNewsLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getCompanyNews}?company_id=${id}&limit=5`
      );
      setNewsDetails(response?.data?.data?.data);
    } catch (error) {
    } finally {
      setNewsLoading(false);
    }
  };
  const fetchCompanyInvestmentAndDealsDetails = async () => {
    try {
      setDealAndInvestmentLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getDealsAndInvestmentDetails}?company_id=${id}&limit=5`
      );
      setDealsAndInvestmentDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setDealAndInvestmentLoading(false);
    }
  };

  const fetchCompanyJobDetails = async () => {
    try {
      setJobLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFilteredJobs}?company_id=${id}&limit=5`
      );
      setJobDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setJobLoading(false);
    }
  };

  const fetchESGRiskRatingChart = async () => {
    try {
      setecgRiskRatingLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getESGRiskRatingChart}?company_id=${id}&limit=5`
      );
      setESGRiskRatingChart(response?.data);
    } catch (error) {
    } finally {
      setecgRiskRatingLoading(false);
    }
  };
  
  const companyData: any[] = ecgRiskRatingDetails?.company || [];

  // Extract unique years from the response data and sort them in descending order
  const years: number[] = companyData.map((item: any) => item.year).sort((a: number, b: number) => b - a);

  // Create a map for easy lookup by year
  const companyDataMap: Record<number, any> = companyData.reduce((acc: Record<number, any>, item: any) => {
    acc[item.year] = item;
    return acc;
  }, {});

  // Create data arrays for each score type based on available years
  const environmentScores: number[] = years.map((year: number) => companyDataMap[year]?.e_score || 0);
  const socialScores: number[] = years.map((year: number) => companyDataMap[year]?.s_score || 0);
  const governanceScores: number[] = years.map((year: number) => companyDataMap[year]?.g_score || 0);
  const totalScores: number[] = years.map((year: number) => companyDataMap[year]?.all_score || 0);

  
  const fetchCompanyPatentDetails = async () => {
    try {
      setPatentLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFilteredPatents}?company_id=${id}&limit=5`
      );
      setPatentsDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setPatentLoading(false);
    }
  };
  const fetchCompanyRegulationsDetails = async () => {
    try {
      setRegulationLoading(true);
      const response = await axios.get(
        `${config.apiEndpoints.getFilteredRegulations}?company_id=${id}&limit=5`
      );
      setRegulationsDetails(response?.data?.data);
    } catch (error) {
    } finally {
      setRegulationLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchCompanyDetails = async () => {
      try {
        setCompanyLoading(true);
        const response = await axios.get(
          `${config.apiEndpoints.getCompanyDetail}/${id}`
        );
        setCompanyDetails(response?.data?.data);
        setIndustryId(response?.data?.data?.category_id);
      } catch (error) {
      } finally {
        setCompanyLoading(false);
      }
    };

    fetchCompanyDetails();
    fetchCompanyNewsDetails();
    fetchCompanyInvestmentAndDealsDetails();
    fetchCompanyPatentDetails();
    fetchCompanyRegulationsDetails();
    fetchCompanyJobDetails();
    fetchESGRiskRatingChart();
    //eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    setHasFinancial(
      companyDetails?.esgScore?.net_income > 0 ||
        companyDetails?.esgScore?.total_revenue > 0 ||
        companyDetails?.esgScore?.total_debt_to_equity > 0 ||
        companyDetails?.esgScore?.ebitda_margin > 0
    );
  }, [companyDetails]);

  const fetchCompanies = async () => {
    setRelatedCompanyLoading(true);
    try {
      let url = `${config.apiEndpoints.getCompanyDropdown}?category_id=${industryId}`;
      const response = await axios.get(url);
      const data = response?.data?.data;
      const randomData = data.sort(() => Math.random() - 0.5).slice(0, 8);

      setCompanyList(randomData || []);
    } catch (error: any) {
      // toast.error("Error fetching regulations");
    } finally {
      setRelatedCompanyLoading(false);
    }
  };

  useEffect(() => {
    if (industryId) {
      fetchCompanies();
    }
    //eslint-disable-next-line
  }, [industryId, id]);

  const riskRating = [
    {
      status: "Negligible",
      min: 0,
      max: 10,
    },
    {
      status: "Low",
      min: 10,
      max: 20,
    },
    {
      status: "Medium",
      min: 20,
      max: 30,
    },
    {
      status: "High",
      min: 30,
      max: 40,
    },
    {
      status: "Severe",
      min: 40,
      max: 100,
    },
  ];

  const jobTableColumnsForSorting = [
    {
      name: "Job Title",
      selector: (row: any) => row.job_title,
      sortable: false,
      key: "job_title",
      label: "Job Title",
    },
    {
      name: "Publisher",
      key: "job_publisher",
      label: "Publisher",
      selector: (row: any) => row.job_publisher,
    },
    {
      name: "Description",
      key: "job_description",
      label: "Description",
      width: "50%",
      render: (row: any) =>
        (row?.job_description?.length > 500
          ? row?.job_description.substring(0, 500) + "..."
          : row?.job_description) || "--",
    },
    {
      name: "Keyword",
      key: "keyword",
      label: "Keyword",
      selector: (row: any) => row.keyword,
    },
    {
      name: "Posted On",
      key: "job_posted_at_datetime_utc",
      label: "Posted On",
      render: (row: any) => formatDate(row?.job_posted_at_datetime_utc),
      width: "12%",
    },
  ];

  const esgTableColumnsForSorting = [
    {
      name: "Company Name",
      key: "company_name",
      label: "Company Name",
      render: (row: any) => (
        <Link to={`/company-detail/${row?.company_id || row?._id}`}>
          <span className="fw-semibold">
            {row?.name || "--"}
          </span>
        </Link>
      ),
      width: "12%",
    },
    {
      name: "Headquarter",
      key: "head_quarter",
      label: "Headquarter",
      render: (row: any) => (
        <Link to={`/company-detail/${row?.company_id || row?._id}`}>
          <span className="fw-semibold">
            {row?.head_quarter?.name || "--"}
          </span>
        </Link>
      ),
      width: "12%",
    },
    {
      name: "E Score",
      key: "e_score",
      label: "E Score",
      render: (row: any) => (
        <div style={{ fontWeight: "bold" }}>
          {row?.latest_research?.e_score || "0"}
        </div>
      ),
      width: "12%",
    },
    {
      name: "S Score",
      key: "s_score",
      label: "S Score",
      render: (row: any) => (
        <div style={{ fontWeight: "bold" }}>
          {row?.latest_research?.s_score || "0"}
        </div>
      ),
      width: "12%",
    },
    {
      name: "G Score",
      key: "g_score",
      label: "G Score",
      render: (row: any) => (
        <div style={{ fontWeight: "bold" }}>
          {row?.latest_research?.g_score || "0"}
        </div>
      ),
      width: "12%",
    },
    {
      name: "Performance",
      key: "all_score",
      label: "Performance",
      render: (row: any) => (
        <ScoreBlocks score={row?.latest_research?.all_score || 0} />
      ),
      // render: (row: any) => row?.latest_research?.all_score || "0",
      width: "30%",
    },
    
  ];
  
  
  const patentTableColumnsForSorting = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      render: (row: any) => (
        <div>
          <div>
            <Link
              target="_blank"
              to={`https://patents.google.com/patent/${row?.app_country}${row?.patent_id}${row?.patent_kind}`}
            >
              <span className="fw-semibold">{row?.patent_title}</span>
            </Link>
          </div>
          <div className="mt-3">
            {row?.patent_type && <Badge text={row?.patent_type} className="me-2"/>}
            {row?.company_info?.name && (
              <Badge text={row?.company_info?.name} className="me-2"/>
            )}

            {row?.taxonomy_details?.length > 0 &&
              row?.taxonomy_details.map((detail: any, index: number) => (
                <Badge key={index} text={detail?.pillar} className="mt-3" />
              ))}
          </div>
        </div>
      ),
      width: "20%",
    },
    {
      name: "Abstract",
      render: (row: any) =>
        (row?.patent_abstract?.length > 500
          ? row?.patent_abstract.substring(0, 500) + "..."
          : row?.patent_abstract) || "--",
      sortable: true,
      key: "patent_abstract",
      label: "Abstract",
      width: "50%",
    },
    {
      name: "taxonomy",
      key: "taxonomy_details",
      label: "Taxonomy",
      render: (row: any) =>
        row?.taxonomy?.length > 0
          ? row.taxonomy.map((item: any, index: number) => (
              <Badge key={index} text={item?.name || "--"} className="mt-3" />
            ))
          : "--",
    },

    {
      name: "Patent Date",
      render: (row: any) => formatDate(row?.patent_date) || "--",
      sortable: true,
      key: "patent_date",
      label: "Patent Date",
      width: "10%",
    },
  ];

  const regulationTableColumnsForSorting = [
    {
      key: "title",
      label: "Title",
      sortable: true,
      render: (row: any) => (
        <div>
          {/* Title Link */}
          <div>
            <Link to={row.link} target="_blank" rel="noopener noreferrer">
              <span>{row.title}</span>
            </Link>
          </div>
          {/* Theme, Country, and Texmorny as Badges */}
          <div className="mt-3">
              {row?.theme && <Badge text={row.theme} className="me-2" />}
              {row?.country?.name && <Badge text={row.country.name} className="me-2" />}
              {row?.taxonomy?.map((item: string, index: number) => (
                <Badge key={index} text={item} className="me-2" />
              ))}
          </div>

        </div>
      ),
    },
  ];
  const investmentTableColumnsForSorting = [
    {
      label: "Organization Name	",
      key: "organization_identifier.value",
      sortable: false,
      render: (row: any) => row?.organization_identifier?.value,
    },
    {
      label: "Funding Round		",
      key: "identifier",
      sortable: false,
      render: (row: any) => row?.funding_round_identifier?.value,
    },
    {
      label: "Money Raised",
      key: "funding_round_money_raised",
      sortable: false,
      render: (row: any) => {
        const currencySymbol =
          currencyToSymbolConversion(
            row?.funding_round_money_raised?.currency
          ) || "--";
        const value = row?.funding_round_money_raised?.value || "";
        const formattedValue = formatCurrency(value).replace(/^[\D]*/g, "");
        return `${currencySymbol}${formattedValue}`;
      },
    },
    {
      label: "Lead Investor	",
      key: "is_lead_investor",
      sortable: false,
      render: (row: any) =>
        row.is_lead_investor === true
          ? "Yes"
          : row.is_lead_investor === false
          ? "No"
          : "-",
    },
    {
      label: "Announced On		",
      key: "announced_on",
      sortable: true,
      render: (row: any) => formatDate(row?.announced_on),
    },
  ];
  const fundTableColumnsForSorting = [
    {
      label: "Transaction Name",
      key: "identifier",
      sortable: false,
      render: (row: any) => row?.identifier?.value,
    },
    {
      label: "Categories",
      key: "funded_organization_categories",
      sortable: false,
      render: (row: any) => {
        const categories = row?.funded_organization_categories;
        return categories
          ? categories?.map((category: any) => category.value).join(", ")
          : "";
      },
    },
    {
      label: "Number of Investors	",
      key: "num_investors",
      sortable: false,
      render: (row: any) => (row?.num_investors ? row?.num_investors : "--"),
    },
    {
      label: "Money Raised",
      key: "short_description",
      sortable: false,
      render: (row: any) => {
        const description = row?.short_description || "";
        const amountMatch = description.match(/\$([\d,]+)/);
        if (!amountMatch || amountMatch.length < 2) return "-";
        const amount = parseFloat(amountMatch[1].replace(/,/g, ""));
        const formattedAmount = formatCurrency(amount);
        return formattedAmount;
      },
    },
    {
      label: "Lead Investors",
      key: "lead_investor_identifiers",
      sortable: false,
      render: (row: any) =>
        row?.lead_investor_identifiers &&
        row?.lead_investor_identifiers?.map((inv: any) => inv?.value).join(","),
    },
    {
      label: "Announced Date	",
      key: "announced_on",
      sortable: true,
      render: (row: any) => formatDate(row?.announced_on),
    },
  ];

  const handleTabChange = (page: string) => {
    const element = document.getElementById(page);
    if(config.chart == page){
      setActiveTab(page);
    }
    if(activeTab == config.chart){
      setActiveTab(page);
       setTimeout(()=>{
        const element = document.getElementById(page);
        const topPosition = element && element?.offsetTop - 100;
        window.scrollTo({
          top: topPosition || 0,
          behavior: "smooth",
        }); 
       },0)
    }
    if (element) {
      const topPosition = element.offsetTop - 100;
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      }); 
          
      setActiveTab(page);
    }
  };
  
  const renderCurrencyValue = (currency: any, value: any): string => {
    // Check if the value is "N/A" or a valid number
    if (value === "N/A") {
      return "N/A";
    }
  
    // Format the value with thousand separators
    const formattedValue = new Intl.NumberFormat('en-US').format(value);
  
    // Add the currency symbol only if the value is not "N/A"
    const currencySymbol = currencyToSymbolConversion(currency) || "$";
  
    return `${currencySymbol}${formattedValue}`;
  };

  return (
    <>
      <section className="companies_sec">
        <div className="row justify-content-between">
          <div className="col-lg-7">
            <h5 className="fw-semibold font-s-22 text_purple">
              {companyLoading ? (
                <Skeleton height={30} width={600} className="mb-2" />
              ) : companyDetails?.name ? (
                `${companyDetails?.name}`
              ) : (
                ""
              )}
            </h5>
            <p className="mb-5">
              {companyDetails?.description?.length > 200 ? (
                <>
                  {companyDetails?.description.substring(0, 200)}
                  ...
                  <span
                    className="text-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowOverviewModal(true)}
                  >
                    Read More
                  </span>
                </>
              ) : (
                companyDetails?.description || " "
              )}
            </p>
          </div>
          <div className="col-lg-4">  
            <div className="card mb-0">
              <div className="card-body">
                  <div className="mb-2">
                      <p><b>Sector</b> </p>
                      <p className="text-secondary">{companyDetails?.category?.parent?.name || "--"}</p>
                  </div>
                  <div className="">
                      <p><b>Industry</b> </p> 
                      <p className="text-secondary">{companyDetails?.category?.name || "--"}</p>
                  </div>

              </div>
            </div>
          </div>
        </div>
        <div className="row">
          
          <div className="col-lg-12">
           <TabHeader
              activeTab={activeTab}
              tabs={[
                { label: "Overview", value: "overview" },
                hasFinancial && { label: "Financial", value: "financial" },
                { label: "Patent", value: "patents" },
                { label: "Regulations", value: "regulations" },
                { label: "Jobs", value: "jobs" },
                // { label: "Charts", value: "charts" },
              ].filter(Boolean)}
              onTabChange={handleTabChange}
            />
          </div>
          {activeTab !== config.chart ? (
            <>
              <div className="col-lg-3 col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>Operating Status</b> </p>
                    <p className="text-secondary mt-2">
                      {companyDetails?.operating_status
                        ? companyDetails.operating_status.charAt(0).toUpperCase() + companyDetails.operating_status.slice(1)
                        : "--"}
                    </p>                     
                     </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>Headquarters </b> </p>
                        <p className="text-secondary mt-2">{companyDetails?.head_quarter?.name || "--"}{" "}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>Employment Strength</b> </p>
                        <p className="text-secondary mt-2">{companyDetails?.num_employees_enum || "--"}</p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg-3 col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="d-flex">
                <div className="flex-shrink-0 mt-2"></div>
                <div className="flex-grow-1 ms-3">
                    <div className="mb-1"><span>Website</span></div>
                    <div className="mt-2">
                      <Link to={companyDetails?.website_url || "#"} target="_blank" rel="noopener noreferrer">
                        <span className="text_purple fw-bold">{companyDetails?.website_url || "--"}</span>
                      </Link>
                    </div> 
                </div>
            </div>
                    </div>
                  </div>
              </div>
            
              <div className="col-lg-8 mt-4">
                <div className="">
                  <div className="row">
                    <div className="col-12">
                      <div className="px-2">
                        <div className="row">
                          <div className="col-lg-6 ps-lg-0 pe-lg-0">
                            {companyLoading ? (
                              <Skeleton height={200} width={"100%"} />
                            ) : (
                              <div className="companies_sec_border_1 p-3 bg-white h-100">
                                <h6 className="fw-semibold text-black font-s-16">
                                  ESG Risk Rating
                                </h6>
                                <h2 className="fw-semibold text-black">
                                  {companyDetails?.esgScore?.all_score || 0}{" "}
                                  <span className="font-s-16">/100</span>
                                </h2>
                                <ul className="d-flex text-center esg_risk_rating">
                                  {riskRating?.map((rating, index) => (
                                    <li className="" key={index}>
                                      <div
                                        className={
                                          companyDetails?.esgScore?.all_score >=
                                            rating.min &&
                                          companyDetails?.esgScore?.all_score <=
                                            rating.max
                                            ? "bg_alart_3"
                                            : "bg_alart_1"
                                        }
                                      >
                                        {rating?.status}
                                      </div>
                                      <span>
                                        {rating?.min}-{rating?.max}
                                      </span>
                                    </li>
                                  ))}
                                </ul>
                                <p className="mt-3">
                                  Calculated using our{" "}
                                  <Link
                                    to={"#"}
                                    className="text-decoration-underline"
                                  >
                                    ESG Risk Rating Methodology
                                  </Link>
                                </p>
                                <p className="mt-1">
                                  Last Updated:{" "}
                                  {formatDate(companyDetails?.updatedAt) ||
                                    "--"}
                                </p>
                                <p className="mt-1">
                                  <Link
                                    to={"#"}
                                    className="text-decoration-underline"
                                  >
                                    View Company’s Business Model
                                  </Link>
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="col-lg-6 ps-lg-0 pe-lg-0">
                            {companyLoading ? (
                              <Skeleton height={200} width={"100%"} />
                            ) : (
                              <div className="companies_sec_border_2 p-3 bg-white">
                                <div>
                                  <HighchartsReact
                                    highcharts={Highcharts}
                                    options={{
                                      chart: {
                                        type: "column",
                                        backgroundColor: null, // Matches the transparent background
                                        borderRadius: 8,
                                        spacing: [10, 10, 15, 10],
                                      },
                                      title: {
                                        text: "ESG Risk Rating Distribution",
                                        style: {
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          color: "#333333",
                                        },
                                      },
                                      xAxis: {
                                        categories: years, // Use available years as categories
                                        crosshair: true,
                                        title: {
                                          text: "ESG Score Range",
                                          style: {
                                            color: "#666666",
                                            fontSize: "12px",
                                          },
                                        },
                                      },
                                      series: [
                                        // Environment Score
                                        {
                                          name: "Environment Score",
                                          data: environmentScores,
                                          color: "#351049", // Color for Environment
                                        },
                                        // Social Score
                                        {
                                          name: "Social Score",
                                          data: socialScores,
                                          color: "#5CCBF5", // Color for Social
                                        },
                                        // Governance Score
                                        {
                                          name: "Governance Score",
                                          data: governanceScores,
                                          color: "#BDEAFB", // Color for Governance
                                        },
                                        // Total Score
                                        {
                                          name: "Total Score",
                                          data: totalScores,
                                          color: "#D3A8EC", // Color for Total
                                        },
                                      ],
                                      legend: {
                                        enabled: false, // Disable the legend
                                      },
                                      tooltip: {
                                        headerFormat:
                                          '<span style="font-size:10px">{point.key}</span><table>',
                                        pointFormat:
                                          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                                          '<td style="padding:0"><b>{point.y}</b></td></tr>',
                                        footerFormat: "</table>",
                                        shared: true,
                                        useHTML: true,
                                      },
                                      credits: {
                                        enabled: false,
                                      },
                                    }}
                                  />
                                </div>
                                <div className="text-end ">{companyLoading ? (
                                    <Skeleton height={30} width={100} />
                                  ) : (<Link className="text-decoration-underline" to={`/company-charts/${id}`} >View Company Dashboard</Link>)}</div>
                              </div>
                            )}
                          </div>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mt-4">
                <div className="mb-0 card">
                  <div className="border bg-info bg-opacity-10 rounded-top p-2">
                    <h5 className="text-black fw-semibold mb-0 text-center">
                      Top ESG News
                    </h5>
                  </div>
                  <div
                    className="p-3"
                    style={{ height: "385px", overflowY: "scroll" }}
                  >
                    {newsLoading ? (
                      // Show skeleton loaders while news is loading
                      <>
                        {Array.from({ length: 5 }).map((_, index) => (
                          <Fragment key={index}>
                                <div className="row">
                                  <div className="col-lg-3 col-3">
                                    <Skeleton height={80} width={80} />
                                  </div>
                                  <div className="col-lg-9 col-9">
                                    <h6>
                                      <Skeleton height={20} width={"80%"} />
                                    </h6>
                                    <p>
                                      <Skeleton height={15} width={"95%"} />
                                      <Skeleton height={15} width={"90%"} />
                                    </p>
                                  </div>
                            </div>
                          </Fragment>
                        ))}
                      </>
                    ) : newsDetails?.length > 0 ? (
                      // Show news items when loaded
                      newsDetails.map((news: any, index: number) => (
                        <Fragment key={index}>
                          <div className="col-12 mb-3">
                            <div className="border bg-white bg-opacity-10 px-3 py-2">
                              <div className="row">
                                <div className="col-lg-3 col-3">
                                  <img
                                    src={news?.image}
                                    alt="img"
                                    style={{
                                      height: "80px",
                                      width: "80px",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>
                                <div className="col-lg-9 col-9">
                                  <h6 className="fw-semibold text-black">
                                    <Link to={news?.url}>
                                      {news?.title.length > 70
                                        ? news?.title.substring(0, 70) + "..."
                                        : news?.title}
                                    </Link>
                                  </h6>
                                  <p>
                                    {news?.summary.length > 100
                                      ? news?.summary.substring(0, 100) + "..."
                                      : news?.summary}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      ))
                    ) : (
                      // Show a message if no news is available
                      <div className="col-12 text-center mt-4">
                        <p>No news available.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
            ) : null}
          {
              activeTab === config.chart ?
                 <>
                    <CompanyCharts />
                 </>
              :
              <>
          {/* Financial Data */}
          <div className="col-lg col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>Net Income</b> </p>
                        <p className="text-secondary mt-2">
                        {companyDetails?.esgScore?.net_income == 0 || !companyDetails?.esgScore?.net_income ? "N/A" : renderCurrencyValue("USD", companyDetails.esgScore.net_income)}                      
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>Total Revenue </b> </p>
                        <p className="text-secondary mt-2">
                        {companyDetails?.esgScore?.total_revenue == 0 || !companyDetails?.esgScore?.total_revenue ? "N/A" : renderCurrencyValue("USD", companyDetails.esgScore.total_revenue)}
                           {/* {renderCurrencyValue("USD", companyDetails?.esgScore?.total_revenue || "N/A")} */}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>Gross Profit</b> </p>
                        <p className="text-secondary mt-2"> 
                        {companyDetails?.esgScore?.gross_profit == 0 || !companyDetails?.esgScore?.gross_profit ? "N/A" : renderCurrencyValue("USD", companyDetails.esgScore.gross_profit)}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>Debt/Equity</b> </p>
                        <p className="text-secondary mt-2">
                        {companyDetails?.esgScore?.total_debt_to_equity == 0 || !companyDetails?.esgScore?.total_debt_to_equity ? "N/A" : renderCurrencyValue("USD", companyDetails.esgScore.total_debt_to_equity)}
                          </p>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="col-lg col-sm-6 mt-4">
                  <div className="card mb-0 h-100">
                    <div className="card-body">
                    <div className="">
                        <p><b>EBITDA Margin</b> </p>
                        <p className="text-secondary mt-2">
                        {companyDetails?.esgScore?.ebitda_margin == 0 || !companyDetails?.esgScore?.ebitda_margin ? "N/A" : renderCurrencyValue("USD", companyDetails.esgScore.ebitda_margin)}
                        </p>
                      </div>
                    </div>
                  </div>
              </div>
          {/* Deals */}
          <div className="col-12 mt-4" id="patents">
          <div className="d-flex align-items-center mb-3">
              <h4 className="mb-0">
                <b>Funding Round</b>
              </h4>
            </div>
            <div className="card mb-0 card-border table_layout2">
              <div className="position-relative">
                <DataTable
                  loading={dealAndInvestmentLoading}
                  data={dealsAndInvestmentDetails?.fund?.data || []}
                  columns={fundTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Deals Found",
                  }}
                />
              </div>
            </div>
          </div>
          {/* Investment */}
          <div className="col-12 mt-4" id="patents">

            <div className="d-flex align-items-center mb-3">
              <h4 className="mb-0">
                <b>Investment</b>
              </h4>
            </div>

            <div className="card mb-0 card-border table_layout2">
              <div className="position-relative">
                
                <DataTable
                  loading={dealAndInvestmentLoading}
                  data={dealsAndInvestmentDetails?.investment?.data || []}
                  columns={investmentTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Investment Found",
                  }}
                />
              </div>
            </div>
          </div>
          {/* Patent */}
          <div className="col-12 mt-4" id="patents">

            <div className="d-flex align-items-center mb-3">
                  <h4 className="mb-0">
                    <b>Patent</b>
                  </h4>
                  <div className="ms-auto">
                    {patentDetails?.length > 0 ? (
                      <Link to={`/dataset/patent/${companyDetails?._id}`}>
                        View All
                      </Link>
                    ) : (
                      <Link to={`/dataset/patent`}>View All</Link>
                    )}
                  </div>
            </div>

            <div className="card mb-0 card-border table_layout2">
              <div className="position-relative">
                
                <DataTable
                  loading={patentLoading}
                  data={patentDetails || []}
                  columns={patentTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Patent Found",
                  }}
                />
              </div>
            </div>
          </div>

          {/* Regulation */}
          <div className="col-12 mt-4" id="regulations">

            <div className="d-flex align-items-center mb-3">
              <h4 className="mb-0">
                <b>Regulation</b>
              </h4>
              <div className="ms-auto">
                {regulationsDetails?.length > 0 ? (
                  <Link to={`/regulations-list/${companyDetails?._id}`}>
                    View All
                  </Link>
                ) : (
                  <Link to={`/regulations-list`}>View All</Link>
                )}
              </div>
            </div>

            <div className="card mb-0 card-border table_layout2">
              <div className="position-relative">
                <DataTable
                  loading={regulationLoading}
                  data={regulationsDetails || []}
                  columns={regulationTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Regulation Found",
                  }}
                />
              </div>
            </div>
          </div>

          {/* JOB */}

          <div className="col-12 mt-4" id="jobs">
            <div className="d-flex align-items-center mb-3">
              <h4 className="mb-0">
                <b>Job</b>
              </h4>
              <div className="ms-auto">
                {jobDetails?.length > 0 ? (
                  <Link to={`/dataset/jobs/${companyDetails?._id}`}>
                    View All
                  </Link>
                ) : (
                  <Link to={`/dataset/jobs`}>View All</Link>
                )}
              </div>
            </div>
            <div className="card mb-0 card-border table_layout2">
              <div className="position-relative">
                
                <DataTable
                  loading={jobLoading}
                  data={jobDetails || []}
                  columns={jobTableColumnsForSorting}
                  isBorderdTable={false}
                  emptyMessage={{
                    title: "No Job Found",
                  }}
                />
              </div>
            </div>
          </div>
          
          {/* OTHER COMPANIES */}
          <div className="col-12 mt-4" id="company">
              <div className="d-flex align-items-center mb-3">
                <h4 className="mb-0">
                  <b>ESG Score</b>
                </h4>
              </div>
              <div className="card mb-0 card-border table_layout2 table_esg">
                <div className="position-relative">
                  
                  <DataTable
                    loading={relatedCompanyLoading}
                    data={companyList || []}
                    columns={esgTableColumnsForSorting}
                    isBorderdTable={false}
                    emptyMessage={{
                      title: "No Job Found",
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        }
        </div>
      </section>
    </>
  );
};
export default CompanyDetail;
