import LandingLayout from "../../layouts/landing/LandingLayout";
import ContactUsForm from "../../components/common/ContactUsForm";

const ContactUs: React.FC = () => {
  return (
    <LandingLayout>
      <div className="py-lg-10 py-5 bg_light_2">
        <div className="container">
          <div className="row align-items-center">
            {/* Left Form Section */}
            <ContactUsForm />
            {/* Right Information Section */}
            <div className="col-lg-6">
              <div className="ms-lg-4 mt-lg-0 mt-4">
                <h1 className="text_demi text_purple mb-4">
                  Schedule your free demo now
                </h1>
                <h5 className="lh-base mb-3">
                  We’ll tailor your demo to your immediate needs and answer all your 
                  questions.
                </h5>
                <h5 className="lh-base">
                 Get ready to access a world of new age ESG information and explore
                 comprehensive ESG solutions designed for you.
                </h5>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default ContactUs;
