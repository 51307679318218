import React, { useCallback, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticatedAxios } from "../hooks/useAxios";

const Dashboard = () => {
  const axios = useAuthenticatedAxios();
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState<any>([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const navigate = useNavigate();
  const debounceTimer = useRef<any>(null);
  const GlobalSearchIcon = ({ style }: { style?: React.CSSProperties }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={style}
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="11" cy="11" r="8" />
      <line x1="21" y1="21" x2="16.65" y2="16.65" />
    </svg>
  );
  
  // Debounced fetchSuggestions
  const fetchSuggestions = useCallback(
    async (query: string) => {
      if (!query || query?.length < 3) {
        setSuggestions([]);
        setDropdownVisible(false);
        if (!query.length) {
          setSearchTerm("");
        }

        return;
      }

      setLoading(true);
      try {
        const endpoint = query
          ? `/company-dropdown?q=${query}`
          : `/company-dropdown`;

        const response = await axios.get(endpoint);
        setSuggestions(response?.data?.data || []); // Assuming API returns an array of suggestions
        setDropdownVisible(true);
        // if (response?.data?.data?.length === 1) {
        //   handleSuggestionClick(response?.data?.data[0]);
        // }
      } catch (error) {
        setSuggestions([]);
      } finally {
        setLoading(false); // Set loading to false once the request is complete
      }
    },
    [axios]
  );
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDropdownVisible(true);
    const value = e.target.value;
    setSearchTerm(value);

    if (!value) {
      setDropdownVisible(false);
      setSuggestions([]);
      fetchSuggestions("");
      return;
    }

    // Clear the previous debounce timer
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    // Set a new debounce timer
    debounceTimer.current = setTimeout(() => {
      fetchSuggestions(value);
    }, 300); // 300ms debounce delay
  };
  const handleSuggestionClick = (suggestion: any) => {
    setSearchTerm(suggestion?.name || "");
    setDropdownVisible(false);
    navigate(`/company-detail/${suggestion._id}`);
  };

  return (
    <section>
      <div className="row justify-content-center">
        <div className="col-lg-9 text-center">
          <h2 className="text_purple fw-semibold pt-17">
            Best-in-class ESG Intelligence SaaS Platform
          </h2>
          <p className="text_purple font-s-18">
            NeoImpact’s ESG platform runs on a comprehensive set of ESG
            fundamental and alternative data sets designed to provide robust and
            real-time ESG insights and analytics.
          </p>
        </div>
        <div className="col-lg-8 mt-3 mt-lg-8">
          <form className="homesearch" onSubmit={(e) => e.preventDefault()}>
            <input
              type="text"
              name="search"
              placeholder="Search companies with at least three letters"
              className="example"
              value={searchTerm}
              onChange={handleInputChange}
            />
            {/* Replace the submit input with a button containing the icon */}
            <button
              type="submit"
              style={{
                background: "none",
                border: "none",
                position: "absolute",
                width: "20px",
                right: "20px",
                top: "17px",
              }}
            >
           <GlobalSearchIcon />
            </button>
          </form>
          {isDropdownVisible && (
            <ul className="dropdown-select mt-2 me-2 card">
              {loading ? (
                <li className="text-center">Loading...</li>
              ) : suggestions?.length > 0 ? (
                suggestions.map((suggestion: any, index: number) => (
                  <li
                    key={index}
                    onClick={() => handleSuggestionClick(suggestion)}
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                  >
                    {suggestion?.name}
                  </li>
                ))
              ) : (
                <li className="text-center">No companies found</li>
              )}
            </ul>
          )}
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
